import { useLazyQuery } from "@apollo/client";
import GET_INBOX_NOTES from "@graphql/queries/GetInboxNotes";
import GET_INBOX_TAB_COUNT from "@graphql/queries/GetInboxTabCount";
import { endOfDay, startOfDay } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState, useCallback } from "react";

import DateRangeSelection from "./DateRangeSelection";
import InboxWindow from "./InboxWindow";
import { inboxStepsBasic } from "../../assets/data/JoyrideTourSteps";
import { TAB_ARRAY } from "../../constants";
import {
  useAuth,
  useNoteMultiselectContext,
  useWatchNoteUpdates,
} from "../../hooks";
import { useTeam } from "../../hooks/use-team";
import JoyrideTour from "../common/modals/JoyrideTour";
import { MultiSelectActionContainer } from "../common/multiselect/MultiSelectActionContainer";
import Tabs from "../common/tabs/Tabs";
import PageTitle from "../layout/PageTitle";
import TeamFilterDropdown from "../teams/TeamFilterDropdown";

const defaultDateRange = {
  startDate: null,
  endDate: null,
  key: "selection",
};

const formatDateTime = (date) => {
  return date ? new Date(date).toISOString() : null;
};

export default function Inbox() {
  // States and constants
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem("lastActiveTab");
    return savedTab !== null ? savedTab : "savedDrafts";
  });
  const [selectedRange, setSelectedRange] =
    useState(defaultDateRange);
  const activeTabObject = TAB_ARRAY.find(
    (tab) => tab.id === activeTab,
  );
  const { currentTeam } = useTeam();
  const [notesData, setNotesData] = useState(null);
  const [tabCountData, setTabCountData] = useState(null);
  const [notesLoading, setNotesLoading] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [queryOptions, setQueryOptions] = useState({
    filter: activeTabObject?.serverFilterKey,
    limit: 15,
    offset: 0,
    startDate: null,
    endDate: null,
    teamUuid: currentTeam?.uuid,
    userUuids: selectedTeamMembers,
  });

  // Hooks
  const { resetSelectedNotesList } = useNoteMultiselectContext();
  const { pollInterval } = useFlags();
  const { userUuid } = useAuth();

  // Handlers
  const handleApplyDateRange = (startDate, endDate) => {
    const formattedStartDate = startDate
      ? formatDateTime(startOfDay(startDate))
      : null;
    const formattedEndDate = endDate
      ? formatDateTime(endOfDay(endDate))
      : null;
    setSelectedRange({
      key: "selection",
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    setQueryOptions((prevOptions) => ({
      ...prevOptions,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
  };

  useEffect(() => {
    setQueryOptions((prevOptions) => {
      const updatedOptions = {
        ...prevOptions,
        teamUuid: currentTeam?.uuid,
        userUuids: selectedTeamMembers,
      };
      return updatedOptions;
    });
  }, [currentTeam, selectedTeamMembers]);

  useEffect(() => {
    if (selectedTeamMembers !== [userUuid]) {
      setSelectedTeamMembers([userUuid]);
    }
  }, [currentTeam]);

  const [tabCountRefetch] = useLazyQuery(GET_INBOX_TAB_COUNT, {
    pollInterval: pollInterval ?? 10000,
    variables: queryOptions,
    onCompleted: (data) => {
      setTabCountData(data);
    },
  });

  const [notesRefetch, { fetchMore }] = useLazyQuery(
    GET_INBOX_NOTES,
    {
      pollInterval: pollInterval ?? 10000,
      variables: queryOptions,
      onError: () => {
        setNotesLoading(false);
      },
      onCompleted: (data) => {
        setNotesData(data);
        setNotesLoading(false);
      },
    },
  );

  // Data fetching functions
  const fetchNotes = useCallback(() => {
    notesRefetch({ variables: queryOptions });
    tabCountRefetch({ variables: queryOptions });
  }, [queryOptions, notesRefetch, tabCountRefetch]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleFetchMore = async () => {
    const { data: newData } = await fetchMore({
      variables: {
        ...queryOptions,
        offset: notesData.inboxNotes.notes.length,
      },
    });
    setNotesData((prevData) => ({
      inboxNotes: {
        ...prevData.inboxNotes,
        notes: [
          ...prevData.inboxNotes.notes,
          ...newData.inboxNotes.notes,
        ],
        totalNotes: newData.inboxNotes.totalNotes,
      },
    }));
    setNotesLoading(false);
  };

  // Effects
  useEffect(() => {
    localStorage.setItem("lastActiveTab", activeTab); // Update local storage when the activeTab changes, record the last active tab
  }, [activeTab]);

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  useEffect(() => {
    // show loading only on tab change and first load
    // otherwise, other note actions will show loading
    // and the UI will feel jumpy
    setNotesLoading(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setQueryOptions((prevOptions) => {
        const updatedOptions = {
          ...prevOptions,
          filter: activeTabObject?.serverFilterKey,
        };
        return updatedOptions;
      });
      setShouldRefetch(false);
    };

    fetchData();
  }, [activeTab, shouldRefetch]);

  useWatchNoteUpdates({
    refetch: () => {
      setShouldRefetch(true);
    },
  });

  // Rendering functions

  return (
    <>
      <JoyrideTour
        steps={inboxStepsBasic}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />

      <PageTitle
        title="Inbox"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
        showHelpButton
      >
        {currentTeam?.uuid && (
          <TeamFilterDropdown
            selectedTeamMembers={selectedTeamMembers}
            setSelectedTeamMembers={setSelectedTeamMembers}
          />
        )}
        <DateRangeSelection
          selectedRange={selectedRange}
          handleApplyDateRange={handleApplyDateRange}
        />
      </PageTitle>

      <div className="mt-6">
        <Tabs
          id="inboxTabs"
          tabArray={TAB_ARRAY}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabCounts={tabCountData?.inboxTabCount}
          handleTabClick={(tabId) => {
            if (tabId !== activeTab) {
              resetSelectedNotesList();
              setNotesLoading(true);
              setActiveTab(tabId);
            }
          }}
        >
          {TAB_ARRAY.map((tab) => {
            if (tab.id !== activeTab) {
              return null;
            }

            return (
              <div key={tab.id} id={tab.id}>
                <InboxWindow
                  tabId={tab.id}
                  tabTitle={tab.title}
                  colorStyles={tab.colorStyles}
                  description={[tab.description]}
                  activeTab={activeTab}
                  isExportWindow={tab?.isExportWindow}
                  isAlreadyExported={!!tab?.isAlreadyExported}
                  data={notesData}
                  refetch={() => {
                    setShouldRefetch(true);
                  }}
                  fetchMore={handleFetchMore}
                  notesLoading={notesLoading}
                />
              </div>
            );
          })}
        </Tabs>
      </div>
      <MultiSelectActionContainer
        notes={notesData?.inboxNotes?.notes}
        refetch={fetchNotes}
      />
    </>
  );
}
