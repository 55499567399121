import { useMutation } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { useTeam } from "../../hooks/use-team.js";
import { alert } from "../common/Alert";
import Record from "../common/record/Record";
import { CREATE_QUICK_NOTE } from "../notes/edit/api/mutations";

export function DashboardRecorder({
  setShouldRefetchForNewRecording,
}) {
  const [isRecording, setIsRecording] = useState(false);
  const { control, getValues, reset } = useForm();

  const [createQuickNote] = useMutation(CREATE_QUICK_NOTE);
  const { currentTeam } = useTeam();

  async function createNewNote(
    audioStorageLink,
    storageObjectName,
    jobTypeArg,
  ) {
    const values = getValues();
    let jobType;

    //If a job type is passed in, use that
    //As of now, this should only occur via unsynced recordings
    if (jobTypeArg) {
      jobType = jobTypeArg;
    } else {
      jobType = JSON.parse(
        localStorage.getItem("next-recording-type"),
      );
    }

    if (!jobType) {
      jobType = JSON.parse(localStorage.getItem("recording-type"));
    }

    if (!jobType) {
      jobType = "medical_record";
    }
    createQuickNote({
      variables: {
        noteInput: {
          audioStorageLink: audioStorageLink,
          storageObjectName: storageObjectName,
          createdAt: moment().format(),
          lastUpdatedAt: moment().format(),
          title: values.title,
          jobType,
          teamUuid: currentTeam?.uuid,
        },
      },
    }).then(() => {
      // reset transcript between recordings
      alert("success", "Audio file uploaded!");
      setShouldRefetchForNewRecording(true);
    });
  }

  return (
    <div
      id="dashboardRecorder"
      className="h-[500px] border border-gray-100 bg-white dark:bg-gray-800 dark:border-gray-900/40 rounded-xl shadow-sm flex flex-1 flex-col relative"
    >
      <div className="flex flex-row w-full rounded-xl bg-indigo-50 dark:bg-gray-700/50 border-b border-indigo-100 dark:border-gray-800 p-4 shadow-sm">
        <h3 className="text-gray-700">Create a Recording</h3>
      </div>

      <div className="p-4">
        <Record
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          control={control}
          createNewNote={createNewNote}
          shouldShowTitleInput={true}
          shouldUseSmallSize={true}
          reset={reset}
        />
      </div>
    </div>
  );
}
