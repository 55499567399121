import { useLazyQuery } from "@apollo/client";
import { GET_TEAMS_USERS } from "@graphql/queries/GetTeamsUsers";
import { useAuth } from "@hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BasicButton } from "../../common/buttons/BasicButton";
import OrganizationPage from "../../organization";
import TeamModal from "../TeamModal";

const TeamsManagementPage = () => {
  // State
  const [isShowingCreateTeamModal, setIsShowingCreateTeamModal] =
    useState(false);

  // Hooks
  const { orgUuid, accountType } = useAuth();
  const navigate = useNavigate();

  // Queries
  const [getTeamsUsers, { loading, data, refetch, error }] =
    useLazyQuery(GET_TEAMS_USERS, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    getTeamsUsers();
    if (data?.user?.teams?.length === 1) {
      // If the user only has one team, reroute them to view that team
      navigate(`/teams/${data.user.teams[0].uuid}`);
    }
  }, []);

  // Helper Functions
  const showCreateTeamModal = () => {
    setIsShowingCreateTeamModal(true);
  };

  const hideCreateTeamModal = () => {
    setIsShowingCreateTeamModal(false);
  };

  // If the user is on a free plan, do not let them access teams
  if (accountType && accountType !== "unlimited") {
    return (
      <div className="flex flex-col h-[600px] items-center justify-center">
        <div className="flex flex-col items-center space-y-10 bg-yellow-500/10 p-10 rounded-xl border border-yellow-200">
          <h2>Whoops, we can&apos;t take you there.</h2>
          <h5 className="max-w-xl text-center">
            Teams mode is an unlimited plan feature. To access it,
            please upgrade your account!
          </h5>
          <a
            className="dark:text-indigo-300 text-indigo-500"
            target="_blank"
            rel="noreferrer"
            href="https://docs.scribenote.com/en/articles/8618740-teams-mode"
          >
            Learn more about Teams Mode
          </a>
        </div>
      </div>
    );
  }

  // If the user has no teams, return an empty state
  if (data?.user?.teams?.length === 0) {
    return (
      <div className="flex flex-col h-[600px] items-center justify-center">
        <TeamModal
          isOpen={isShowingCreateTeamModal}
          onClose={hideCreateTeamModal}
          orgUuid={orgUuid}
          isCreatingTeam={true}
          refetch={refetch}
        />
        <div className="flex flex-col items-center space-y-10 bg-yellow-500/10 p-10 rounded-xl border border-yellow-200">
          <h2>Welcome to Teams Mode!</h2>
          <h5 className="max-w-xl text-center">
            You don&apos;t have any teams yet.{" "}
            <a
              className="dark:text-indigo-300 text-indigo-500"
              target="_blank"
              rel="noreferrer"
              href="https://docs.scribenote.com/en/articles/8618740-teams-mode"
            >
              Learn more about Teams Mode
            </a>
          </h5>
          <BasicButton
            importance="primary"
            onClick={showCreateTeamModal}
          >
            Create your first team
          </BasicButton>
        </div>
      </div>
    );
  }

  return (
    <OrganizationPage
      refetchTeams={refetch}
      loadingTeams={loading}
      userTeams={data?.user?.teams}
      userTeamsError={error}
    />
  );
};

export default TeamsManagementPage;
