import { useMutation } from "@apollo/client";
import {
  faSort,
  faMinusCircle,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { useState, useEffect, useCallback } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import DEACTIVATE_USER from "../../graphql/mutations/DeactivateUser";
import REMOVE_USER_FROM_TEAM from "../../graphql/mutations/RemoveUserFromTeam";
import { useAuth } from "../../hooks";
import { useTeam } from "../../hooks/use-team";
import { alert } from "../common/Alert";
import { ConfirmModal } from "../common/modals/PopupModals";

const mapRoleIdToRole = (roleId) => {
  return (
    {
      1: "DVM",
      2: "Practice Manager",
      3: "Locum",
      4: "Technician",
      5: "Other",
    }[roleId] || "Role Not Assigned"
  );
};

export function EditExistingTeamMembers({
  team,
  isCurrentUserAnAdmin,
}) {
  //constants
  const teamUuid = team?.uuid;
  const { allowUserDeactivation } = useFlags();

  //state
  const [isSortReverse, setIsSortReverse] = useState(false);
  const [isShowingRemoveModal, setIsShowingRemoveModal] =
    useState(false);
  const [isShowingDeactivateModal, setIsShowingDeactivateModal] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [sortedUsers, setSortedUsers] = useState([]);

  //hooks
  const { userUuid } = useAuth();
  const { refreshCurrentTeam } = useTeam();

  //mutations
  const [removeUserFromTeam] = useMutation(REMOVE_USER_FROM_TEAM);
  const [deactivateUser] = useMutation(DEACTIVATE_USER);

  //effects
  useEffect(() => {
    if (team) {
      setSortedUsers(team?.users);
    }
  }, [team]);

  //functions
  const sortUsers = useCallback(
    (sortBy) => {
      setIsSortReverse(!isSortReverse);

      const sorted = [...sortedUsers]?.sort((a, b) => {
        const aValue = a[sortBy] ?? "";
        const bValue = b[sortBy] ?? "";

        if (
          typeof aValue === "boolean" &&
          typeof bValue === "boolean"
        ) {
          // Convert booleans to numbers (true = 1, false = 0) and compare
          return isSortReverse
            ? (bValue ? 1 : 0) - (aValue ? 1 : 0)
            : (aValue ? 1 : 0) - (bValue ? 1 : 0);
        } else if (
          typeof aValue === "number" ||
          typeof bValue === "number"
        ) {
          // Handle numeric sorting
          return isSortReverse ? bValue - aValue : aValue - bValue;
        } else {
          // Convert values to strings and handle string sorting with localeCompare
          return isSortReverse
            ? String(bValue).localeCompare(String(aValue))
            : String(aValue).localeCompare(String(bValue));
        }
      });

      setSortedUsers(sorted);
    },
    [isSortReverse, sortedUsers],
  );

  const handleRemoveClick = (user) => {
    setSelectedUser(user);
    setIsShowingRemoveModal(true);
  };

  const handleDeactivateClick = (user) => {
    setSelectedUser(user);
    setIsShowingDeactivateModal(true);
  };

  const handleConfirmRemoveFromTeam = async () => {
    try {
      await removeUserFromTeam({
        variables: {
          teamUuid: teamUuid,
          userUuid: selectedUser?.uuid,
        },
      });
      setIsShowingRemoveModal(false);
      setSortedUsers((prevUsers) =>
        prevUsers.filter((user) => user.uuid !== selectedUser.uuid),
      );
      refreshCurrentTeam(team);

      alert(
        "success",
        `${selectedUser?.firstName} ${selectedUser?.lastName} has been removed from the team.`,
      );
      mixpanel.track("Remove User From Team", {
        teamUuid: teamUuid,
        userUuid: selectedUser?.uuid,
      });
    } catch (error) {
      alert("error", "Error removing user from team.");
      mixpanel.track("Remove User From Team Error", {
        teamUuid: teamUuid,
        userUuid: selectedUser?.uuid,
        orgUuid: team?.orgUuid || team?.organization?.uuid,
        error: error,
      });
    }
  };

  const handleConfirmDeactivateUser = async () => {
    const rmTeamUserInput = {
      teamUuid: team?.uuid,
      userUuid: selectedUser?.uuid,
      orgUuid: team?.orgUuid || team?.organization?.uuid,
    };
    try {
      await deactivateUser({
        variables: { rmTeamUserInput },
      });
      setIsShowingDeactivateModal(false);
      setSortedUsers((prevUsers) =>
        prevUsers.filter((user) => user.uuid !== selectedUser.uuid),
      );
      refreshCurrentTeam(team);
      alert(
        "success",
        `${selectedUser?.firstName} ${selectedUser?.lastName} has been deactivated.`,
      );
      mixpanel.track("Deactivate User", {
        teamUuid: teamUuid,
        userUuid: selectedUser?.uuid,
      });
    } catch (error) {
      alert("error", "Error deactivating user.");
      captureException(error);
    }
  };

  //render
  return (
    <div className="bg-gray-100/70 dark:bg-gray-700/30 border border-gray-50 dark:border-gray-700/50 rounded-lg py-2 px-2 w-full my-4">
      <table className="table-auto w-full dark:text-gray-300 text-left">
        <thead>
          <tr className="border-b dark:border-gray-700/50">
            <TableHeader
              title="First Name"
              sortBy="firstName"
              sortUsers={sortUsers}
            />
            <TableHeader
              title="Last Name"
              sortBy="lastName"
              sortUsers={sortUsers}
            />
            <TableHeader
              title="Email"
              sortBy="email"
              sortUsers={sortUsers}
            />
            <TableHeader
              title="Notes Created"
              sortBy="numAppointmentsUsed"
              sortUsers={sortUsers}
            />
            <TableHeader
              title="Role"
              sortBy="roleId"
              sortUsers={sortUsers}
            />
            <TableHeader
              title="Team Admin"
              sortBy="isAdmin"
              sortUsers={sortUsers}
            />
            {isCurrentUserAnAdmin && (
              <>
                <th className="font-semibold text-sm">Remove</th>
                {allowUserDeactivation && (
                  <th className="font-semibold text-sm">
                    Deactivate
                  </th>
                )}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedUsers?.map((user, index) => {
            const isCurrentUser = user.uuid === userUuid;
            return (
              <tr
                key={index}
                className="even:bg-transparent dark:odd:bg-gray-800 odd:bg-white dark:even:bg-transparent"
              >
                <td>
                  <h5>{user?.firstName}</h5>
                </td>
                <td>
                  <h5>{user?.lastName}</h5>
                </td>
                <td className="py-2">
                  <h5>{user?.email}</h5>
                </td>
                <td>
                  <h5>{user?.numAppointmentsUsed}</h5>
                </td>
                <td>
                  <h5>{mapRoleIdToRole(user?.roleId)}</h5>
                </td>
                <td>
                  <h5>{user?.isAdmin ? "Yes" : "No"}</h5>
                </td>
                {isCurrentUserAnAdmin && (
                  <>
                    <td>
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className={`${
                          isCurrentUser || user.isAdmin
                            ? "text-gray-400 cursor-not-allowed"
                            : "text-red-500 cursor-pointer hover:text-red-700"
                        }`}
                        onClick={() => {
                          if (isCurrentUser || user.isAdmin) {
                            return;
                          }
                          handleRemoveClick(user);
                        }}
                      />
                    </td>
                    {allowUserDeactivation && (
                      <td>
                        <FontAwesomeIcon
                          icon={faBan}
                          className={`${
                            isCurrentUser || user.isAdmin
                              ? "text-gray-400 cursor-not-allowed"
                              : "text-red-500 cursor-pointer hover:text-red-700"
                          }`}
                          onClick={() => {
                            if (isCurrentUser || user.isAdmin) {
                              return;
                            }
                            handleDeactivateClick(user);
                          }}
                        />
                      </td>
                    )}
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isShowingRemoveModal && (
        <ConfirmModal
          shouldShow={isShowingRemoveModal}
          hideModal={() => setIsShowingRemoveModal(false)}
          title="Remove from Team"
          confirmText="Remove"
          confirmAction={handleConfirmRemoveFromTeam}
          cancelText="Cancel"
          cancelAction={() => setIsShowingRemoveModal(false)}
        >
          <p>
            Are you sure you want to remove {selectedUser?.firstName}{" "}
            {selectedUser?.lastName} from{" "}
            {team?.name ? team?.name : "the team"}? You can always add
            them back to the team later. Their notes will still be
            deleted 30 days after their creation.
          </p>
        </ConfirmModal>
      )}
      {isShowingDeactivateModal && (
        <ConfirmModal
          shouldShow={isShowingDeactivateModal}
          hideModal={() => setIsShowingDeactivateModal(false)}
          title={`Deactivate ${selectedUser?.firstName} ${selectedUser?.lastName}`}
          confirmText="Deactivate"
          confirmAction={handleConfirmDeactivateUser}
          cancelText="Cancel"
          cancelAction={() => setIsShowingDeactivateModal(false)}
        >
          <p>
            Are you sure you want to deactivate the account of{" "}
            {selectedUser?.firstName} {selectedUser?.lastName}? This
            will lock them out of their account and you will need to
            contact support to reactivate them. Their notes will still
            be deleted 30 days after their creation.
          </p>
        </ConfirmModal>
      )}
    </div>
  );
}

function TableHeader({ sortBy, title, sortUsers = (soryBy) => {} }) {
  return (
    <th
      className="font-semibold text-sm cursor-pointer"
      onClick={() => sortUsers(sortBy)}
    >
      {title} <FontAwesomeIcon icon={faSort} />
    </th>
  );
}
