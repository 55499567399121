import { useLazyQuery, useMutation } from "@apollo/client";
import { alert } from "@components/common/Alert";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ADD_USERS_TO_TEAM from "@graphql/mutations/AddUsersToTeam";
import CREATE_TEAM from "@graphql/mutations/CreateTeam";
import GET_EXISTING_USERS from "@graphql/queries/GetExistingUsers";
import { useAuth } from "@hooks";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";

import {
  getBillingIncrementText,
  areThereAnyFailedUsers,
} from "./utils";
import { ConfirmModal } from "../../common/modals/PopupModals";
import AddTeamInformation from "../ModalScreens/AddTeamInformation";
import AddUsers from "../ModalScreens/AddUsers";
import ConfirmNewUsers from "../ModalScreens/ConfirmNewUsers";
import NewUsersReceipt from "../ModalScreens/NewUsersReceipt";

const TeamModal = ({
  isOpen,
  onClose,
  orgUuid,
  team = {
    uuid: "",
    users: [],
    orgUuid: "",
    name: "",
  },
  isCreatingTeam = false,
  refetch,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamLocation, setTeamLocation] = useState("");
  const [newUserEmails, setNewUserEmails] = useState([]);
  const [newUsersState, setNewUsersState] = useState([]);
  const [existingUsersState, setExistingUsersState] = useState([]);
  const [deactivatedUsersState, setDeactivatedUsersState] = useState(
    [],
  );
  const [receiptData, setReceiptData] = useState({});
  const [newTeamUuid, setNewTeamUuid] = useState("");
  const [billingIncrementText, setBillingIncrementText] =
    useState("");
  const { refetchUserDetails } = useAuth();

  const stepOffset = isCreatingTeam ? 0 : 1;

  const [addUsersToTeam, { loading: addUsersLoading }] = useMutation(
    ADD_USERS_TO_TEAM,
    {
      onCompleted: (data) => {
        if (areThereAnyFailedUsers(data?.addUsersToTeam?.addUsers)) {
          alert(
            "error",
            "You have custom billing requirements. Please contact support.",
          );
          return;
        }
        setReceiptData(data?.addUsersToTeam?.addUsers);
        refetch();
        setCurrentStep(currentStep + 1);
        mixpanel.track("Users added to team", {
          teamUuid: newTeamUuid || team?.uuid,
          orgUuid: orgUuid,
          addedUsers: data?.addUsersToTeam?.addUsers,
        });
      },
      onError: (error) => {
        console.error("Add users error:", error);
        alert("error", "An error occurred while adding users.");
        mixpanel.track("Error adding users to team", {
          error: error.message,
        });
      },
    },
  );

  const [createTeam, { loading: teamCreationLoading }] = useMutation(
    CREATE_TEAM,
    {
      onCompleted: (data) => {
        setNewTeamUuid(data.createTeam.teamUuid);
        mixpanel.track("Team Created", {
          teamName,
          teamLocation,
          orgUuid: orgUuid,
        });
        setCurrentStep(1);
      },
      onError: (error) => {
        console.error("Create team error:", error);
        alert("error", "An error occurred while creating the team.");
      },
    },
  );

  const [getExistingUsers, { loading: existingUsersLoading }] =
    useLazyQuery(GET_EXISTING_USERS, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setBillingIncrementText(
          getBillingIncrementText(
            data?.existingUsers?.newUsers,
            data?.existingUsers?.existingUsers,
          ),
        );
        const mappedNewUsers = data?.existingUsers?.newUsers?.map(
          (email) => ({
            email,
            firstName: "",
            lastName: "",
            roleId: 0,
            isAdmin: false,
          }),
        );
        setNewUsersState(mappedNewUsers || []);
        setExistingUsersState(
          data?.existingUsers?.existingUsers || [],
        );
        setDeactivatedUsersState(
          data?.existingUsers?.deactivatedUsers || [],
        );
        setCurrentStep(currentStep + 1);
      },
      onError: (error) => {
        console.error("Get existing users error:", error);
        alert(
          "error",
          "An error occurred while fetching existing users.",
        );
      },
    });

  useEffect(() => {
    setErrorMessage("");
  }, [currentStep, teamName, teamLocation]);

  const resetState = () => {
    setCurrentStep(0);
    setErrorMessage("");
    setNewUserEmails([]);
    setNewUsersState([]);
    setExistingUsersState([]);
    setDeactivatedUsersState([]);
    refetch();
  };

  const handleCreateTeam = () => {
    if (!teamName.trim() || !teamLocation.trim()) {
      setErrorMessage(
        "Please enter a team name and location to proceed.",
      );
      return;
    }

    createTeam({
      variables: {
        teamName,
        location: teamLocation,
        createdAt: new Date().toISOString(),
      },
    });
  };

  const handleAddUsers = () => {
    if (newUserEmails.length > 0) {
      getExistingUsers({ variables: { emails: newUserEmails } });
    } else {
      alert("error", "Please add at least one user to continue.");
    }
  };

  const handleConfirmNewUsers = () => {
    // Map to required fields for new users
    const updatedNewUsers = newUsersState.map((user) => ({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roleId: user.roleId,
      isAdmin: user.isAdmin,
    }));

    // Map to required fields for existing users
    const updatedExistingUsers = existingUsersState.map((user) => ({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roleId: user.roleId,
      isAdmin: user.isAdmin,
    }));

    const allInputsFilled = [
      ...updatedNewUsers,
      ...updatedExistingUsers,
    ].every((user) => user.firstName && user.lastName && user.roleId);

    if (!allInputsFilled) {
      setErrorMessage(
        "All users must have a first name, last name, and role.",
      );
      return;
    }

    const addUsersInput = {
      newUserData: updatedNewUsers,
      existingUserData: updatedExistingUsers,
      teamUuid: newTeamUuid || team?.uuid,
      createdAt: new Date(),
    };

    if (addUsersInput.teamUuid) {
      addUsersToTeam({ variables: { addUsersInput } });
    } else {
      console.error("Team UUID is missing in addUsersInput");
      alert(
        "error",
        "Team UUID is missing. Unable to add users to team.",
      );
    }
  };

  const closeModalAndResetState = () => {
    resetState();
    refetchUserDetails();
    onClose();
  };

  const updateUsersAndBillingText = (index, isExisting) => {
    const updateState = isExisting
      ? setExistingUsersState
      : setNewUsersState;

    // Update the state and calculate the new email arrays within the updater function
    updateState((prev) => {
      const updatedUsers = prev.filter((_, i) => i !== index);

      const newUsersEmailArray = isExisting
        ? newUsersState.map((user) => user.email)
        : updatedUsers.map((user) => user.email);

      const existingUsersEmailArray = isExisting
        ? updatedUsers.map((user) => user.email)
        : existingUsersState.map((user) => user.email);

      setBillingIncrementText(
        getBillingIncrementText(
          newUsersEmailArray,
          existingUsersEmailArray,
        ),
      );

      return updatedUsers;
    });
  };

  const steps = [
    {
      component: (
        <AddTeamInformation
          teamName={teamName}
          setTeamName={setTeamName}
          teamLocation={teamLocation}
          setTeamLocation={setTeamLocation}
          error={errorMessage}
        />
      ),
      title: "Create a New Team",
      confirmText: "Next",
      confirmAction: handleCreateTeam,
      cancelText: "Cancel",
      cancelAction: closeModalAndResetState,
    },
    {
      component: (
        <AddUsers
          newUserEmails={newUserEmails}
          setNewUserEmails={setNewUserEmails}
          team={team}
          isNewTeam={isCreatingTeam}
        />
      ),
      title: `Add Users to ${teamName || team?.name}`,
      confirmText: "Next",
      confirmAction: handleAddUsers,
      cancelText: isCreatingTeam ? "Previous" : "Cancel",
      hideCancel: isCreatingTeam,
      cancelAction: () => {
        closeModalAndResetState();
      },
    },
    {
      component: (
        <ConfirmNewUsers
          newUsers={newUsersState}
          existingUsers={existingUsersState}
          deactivatedUsers={deactivatedUsersState}
          setUsersState={({ newUsers, existingUsers }) => {
            setNewUsersState(newUsers);
            setExistingUsersState(existingUsers);
          }}
          onRemove={(index, isExisting) => {
            updateUsersAndBillingText(index, isExisting);
          }}
          error={errorMessage}
          billingIncrementText={billingIncrementText}
        />
      ),
      title: "Confirm New Users",
      confirmText: "Next",
      confirmAction: handleConfirmNewUsers,
      cancelText: "Previous",
      cancelAction: () => setCurrentStep(1 - stepOffset),
      disableConfirm:
        !newUsersState.length && !existingUsersState.length,
    },
    {
      component: <NewUsersReceipt receiptData={receiptData} />,
      title: "Users Added",
      confirmText: "Finish",
      confirmAction: closeModalAndResetState,
      cancelText: "Close Modal",
      cancelAction: closeModalAndResetState,
    },
  ];

  const currentStepData = steps[currentStep + stepOffset];
  const isAnythingLoading =
    teamCreationLoading || existingUsersLoading || addUsersLoading;

  return (
    <ConfirmModal
      shouldShow={isOpen}
      hideModal={closeModalAndResetState}
      title={currentStepData.title}
      confirmText={currentStepData.confirmText}
      confirmAction={currentStepData.confirmAction}
      cancelText={currentStepData.cancelText}
      cancelAction={currentStepData.cancelAction}
      hideCancel={currentStepData.hideCancel || isAnythingLoading}
      hideConfirm={isAnythingLoading}
      width={
        currentStep + stepOffset === 2 ? "max-w-4xl" : "max-w-xl"
      }
      disableConfirm={currentStepData.disableConfirm}
    >
      {isAnythingLoading ? (
        <div className="flex justify-center">
          <FontAwesomeIcon
            icon={faSpinner}
            className="animate-spin text-gray-500 dark:text-gray-400 text-4xl"
          />
        </div>
      ) : (
        <>
          {currentStepData?.component}
          {errorMessage &&
            (newUsersState?.length > 0 ||
              existingUsersState?.length > 0) && (
              <div className="text-red-500 mt-2">{errorMessage}</div>
            )}
        </>
      )}
    </ConfirmModal>
  );
};

export default TeamModal;
