import { gql } from "@apollo/client";
const GET_INBOX_TAB_COUNT = gql`
  query getInboxTabCount(
    $startDate: DateTime
    $endDate: DateTime
    $teamUuid: String
    $userUuids: [String]
  ) {
    inboxTabCount(
      startDate: $startDate
      endDate: $endDate
      teamUuid: $teamUuid
      userUuids: $userUuids
    ) {
      numSavedDrafts
      numNowProcessing
      numForReview
      numForExport
      numExportedItems
    }
  }
`;

export default GET_INBOX_TAB_COUNT;
