import {
  useAuth,
  useDarkMode,
  usePreventRecordingInterruptions,
} from "@hooks";
import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";

export default function TeamUserDropdown({
  propHandleChange,
  currentUser,
  setCurrentUser,
  currentTeam,
}) {
  const [enabled] = useDarkMode();
  const { shouldPreventInterruptions } =
    usePreventRecordingInterruptions();

  const { userUuid } = useAuth();

  useEffect(() => {
    setCurrentUser(userUuid);
  }, [currentTeam]);

  return (
    <>
      <FormControl
        sx={{ position: "relative", mt: 1 }}
        size="small"
        className="w-full"
      >
        <div className="flex flex-row items-center dark:bg-gray-700 bg-gray-100 pl-3 rounded-lg text-indigo-500">
          <Select
            labelId="team-label"
            sx={[
              {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiOutlinedInput-input": {
                  pl: 0,
                },
              },
              {
                fontSize: "14px",
                color: enabled ? "#f9fafb" : "black",
                backgroundColor: enabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                flex: 1,
                width: 160,
              },
            ]}
            disabled={shouldPreventInterruptions}
            // when the dropdown is used to switch users/display the current user it should always reflect the currently linked user
            // even if the user is changed from an external function and not the dropdown
            // when its used to transfer notes (or other actions) it should reflect the currently
            // selected user initially, but the selected value changeable (without changing the current teams user)
            value={currentUser}
            onChange={propHandleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enabled
                    ? "rgb(55 65 81)"
                    : "rgb(243 244 246)",
                },
              },
            }}
          >
            {currentTeam?.users?.map((user) => (
              <MenuItem
                key={user.uuid}
                value={user.uuid}
                sx={{
                  fontSize: "14px",
                }}
              >
                <h4 className="text-sm ml-2 font-medium truncate">
                  {user.firstName} {user.lastName}
                  {userUuid === user.uuid && (
                    <span className="text-xs font-normal">
                      {" "}
                      (You)
                    </span>
                  )}
                </h4>
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
    </>
  );
}
