import { useDarkMode } from "@hooks";
import { useAuth } from "@hooks/use-auth";
import { useTeam } from "@hooks/use-team";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

function TeamFilterDropdown({
  selectedTeamMembers,
  setSelectedTeamMembers,
}) {
  const { currentTeam } = useTeam();
  const { userUuid } = useAuth();
  const handleSelectChange = (event) => {
    setSelectedTeamMembers(event.target.value);
  };

  if (!currentTeam?.users) {
    return null;
  }

  const [enabled] = useDarkMode();
  return (
    <>
      <FormControl>
        <InputLabel
          size="small"
          sx={[
            {
              "&.MuiInputLabel-shrink": {
                top: "-5px",
                color: enabled ? "rgb(209 213 219)" : "black",
                pl: 0,
              },
            },
            {
              pl: 1,
              fontSize: "13px",
              color: enabled ? "rgb(209 213 219)" : "black",
            },
          ]}
          id="team-filter-dropdown-label"
        >
          Filter By Team Member(s)
        </InputLabel>
        <Select
          sx={[
            {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiOutlinedInput-input": {
                pl: 0,
                py: 1,
              },
            },
            {
              fontSize: "14px",
              color: enabled ? "rgb(209 213 219)" : "black",
              backgroundColor: enabled
                ? "rgb(55 65 81)"
                : "rgb(243 244 246)",
              borderRadius: "8px",
              flex: 1,
              width: 300,
            },
          ]}
          className="mx-2 px-2"
          labelId="team-filter-dropdown-label"
          multiple={true}
          value={selectedTeamMembers}
          onChange={handleSelectChange}
          MenuProps={{
            PaperProps: {
              style: {
                color: enabled ? "#f9fafb" : "black",
                backgroundColor: enabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
              },
            },
          }}
        >
          {currentTeam?.users?.map((user) => (
            <MenuItem
              key={user.uuid}
              value={user.uuid}
              sx={{
                fontSize: "14px",
              }}
            >
              <span className="text-sm font-medium truncate">
                {user.firstName} {user.lastName}{" "}
                {user.uuid === userUuid && " (You)"}
              </span>
            </MenuItem>
          ))}
          ;
        </Select>
      </FormControl>
    </>
  );
}

export default TeamFilterDropdown;
