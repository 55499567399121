import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDarkMode } from "@hooks";
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";

const AddUsersTable = ({
  users = [],
  isExisting,
  setUsers,
  onRemove,
  error,
}) => {
  const [inputValues, setInputValues] = useState(users);
  const [enabled] = useDarkMode();

  useEffect(() => {
    if (setUsers) {
      setUsers(inputValues);
    }
  }, [inputValues, setUsers]);

  const handleInputChange = (e, index, field) => {
    const value = e?.target?.value;
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [field]: value,
      };
      return newValues;
    });
  };

  const handleCheckboxChange = (e, index) => {
    const checked = e.target.checked;
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        isAdmin: checked,
      };
      return newValues;
    });
  };

  const selectStyles = useMemo(
    () => ({
      "& .MuiSelect-select": {
        padding: "0.5px 8px",
        color: enabled ? "rgb(209 213 219)" : "black",
        backgroundColor: enabled ? "rgb(55 65 81)" : "white",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    }),
    [enabled],
  );

  const handleRemove = (index, isExisting) => {
    onRemove(index, isExisting);
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      newValues.splice(index, 1);
      return newValues;
    });
  };

  return (
    <table className="table-auto w-full dark:text-gray-300 text-left">
      <thead>
        <tr className="border-b dark:border-gray-700/50">
          <th className="font-semibold text-sm w-1/4">Email</th>
          <th className="font-semibold text-sm w-1/5">First Name</th>
          <th className="font-semibold text-sm w-1/5">Last Name</th>
          <th className="font-semibold text-sm w-1/5">Role</th>
          <th className="font-semibold text-sm w-1/5 text-center">
            Team Admin
          </th>
          <th className="w-1/20"></th>
        </tr>
      </thead>
      <tbody>
        {inputValues?.map((user, index) => (
          <tr key={index}>
            <td className={`w-1/4 px-1 ${index === 0 ? "pt-2" : ""}`}>
              <p className="w-full bg-transparent text-gray-900 dark:text-gray-300">
                {user?.email ?? ""}
              </p>
            </td>
            <td className={`w-1/5 px-1 ${index === 0 ? "pt-2" : ""}`}>
              {isExisting ? (
                <p className="w-full bg-transparent text-gray-900 dark:text-gray-300">
                  {user?.firstName ?? ""}
                </p>
              ) : (
                <input
                  type="text"
                  defaultValue={user?.firstName ?? ""}
                  className={`w-full bg-white dark:bg-gray-700 border ${
                    error && !inputValues[index]?.firstName
                      ? "border-red-500"
                      : "border-none"
                  } text-gray-900 dark:text-gray-300 pl-2 rounded-sm`}
                  onChange={(e) =>
                    handleInputChange(e, index, "firstName")
                  }
                />
              )}
            </td>
            <td className={`w-1/5 px-1 ${index === 0 ? "pt-2" : ""}`}>
              {isExisting ? (
                <p className="w-full bg-transparent text-gray-900 dark:text-gray-300">
                  {user?.lastName ?? ""}
                </p>
              ) : (
                <input
                  type="text"
                  defaultValue={user?.lastName ?? ""}
                  className={`w-full bg-white dark:bg-gray-700 border ${
                    error && !inputValues[index]?.lastName
                      ? "border-red-500"
                      : "border-none"
                  } text-gray-900 dark:text-gray-300 pl-2 rounded-sm`}
                  onChange={(e) =>
                    handleInputChange(e, index, "lastName")
                  }
                />
              )}
            </td>
            <td className={`w-1/5 px-1 ${index === 0 ? "pt-2" : ""}`}>
              <FormControl
                variant="outlined"
                className="w-full bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-300 rounded-sm"
              >
                <Select
                  value={inputValues[index]?.roleId}
                  onChange={(e) =>
                    handleInputChange(e, index, "roleId")
                  }
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: enabled
                          ? "rgb(55 65 81)"
                          : "white",
                        color: enabled ? "rgb(209 213 219)" : "black",
                      },
                    },
                  }}
                  sx={{
                    ...selectStyles,
                    ...(error &&
                      inputValues[index]?.roleId === 0 && {
                        "& .MuiSelect-select": {
                          padding: "2px 8px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(239 68 68)",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "rgb(239 68 68)",
                          },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(239 68 68)",
                        },
                      }),
                  }}
                >
                  <MenuItem value={1}>DVM</MenuItem>
                  <MenuItem value={2}>Practice Manager</MenuItem>
                  <MenuItem value={3}>Locum</MenuItem>
                  <MenuItem value={4}>Technician</MenuItem>
                  <MenuItem value={5}>Other</MenuItem>
                </Select>
              </FormControl>
            </td>
            <td className="w-1/5 text-center px-1">
              <Checkbox
                checked={inputValues[index]?.isAdmin || false}
                onChange={(e) => handleCheckboxChange(e, index)}
                sx={{
                  color: enabled
                    ? "rgb(209, 213, 219)"
                    : "rgb(0, 0, 0)",
                  "&.Mui-checked": {
                    color: enabled
                      ? "rgb(209, 213, 219)"
                      : "rgb(0, 0, 0)",
                  },
                }}
              />
            </td>
            <td className="w-1/20 text-center px-1">
              <FontAwesomeIcon
                icon={faMinusCircle}
                className="text-red-500 hover:text-red-700 cursor-pointer"
                onClick={() => handleRemove(index, isExisting)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AddUsersTable;
