import { useDarkMode } from "@hooks";
import { TextField } from "@mui/material";
import { useMemo } from "react";

const AddTeamInformation = ({
  teamName,
  setTeamName,
  teamLocation,
  setTeamLocation,
  error,
}) => {
  const [enabled] = useDarkMode();

  const inputStyles = useMemo(
    () => ({
      backgroundColor: enabled ? "rgb(55 65 81)" : "white",
      color: enabled ? "rgb(209 213 219)" : "black",
    }),
    [enabled],
  );

  return (
    <>
      <table className="table-auto w-full dark:text-gray-300 text-left">
        <tbody>
          <tr className="border-b dark:border-gray-700/50">
            <td className="font-semibold text-sm w-full p-2">
              Team Name
            </td>
          </tr>
          <tr>
            <td className="w-full pt-2 pb-4">
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter team name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                InputProps={{
                  style: inputStyles,
                }}
                className="bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-300 pl-2 rounded-sm"
              />
            </td>
          </tr>
          <tr className="border-b dark:border-gray-700/50">
            <td className="font-semibold text-sm w-full p-2">
              Team Location
            </td>
          </tr>
          <tr>
            <td className="w-full pt-2 pb-4">
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter team location"
                value={teamLocation}
                onChange={(e) => setTeamLocation(e.target.value)}
                InputProps={{
                  style: inputStyles,
                }}
                className="bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-300 pl-2 rounded-sm"
              />
            </td>
          </tr>
        </tbody>
      </table>
      {error && (
        <div className="text-red-500 mt-2 text-sm">{error}</div>
      )}
    </>
  );
};

export default AddTeamInformation;
