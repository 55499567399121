import { useDarkMode } from "@hooks";
import { TextField, Chip, Autocomplete } from "@mui/material";
import { useState, useEffect, useMemo } from "react";

import { alert } from "../../common/Alert";

export const isValidEmail = (email, array, team, isNewTeam) => {
  if (!isNewTeam) {
    if (array && array?.includes(email)) {
      alert("warning", `${email} is already on this team.`);
      return false;
    }
    const domain = email?.split("@")[1];
    if (
      team?.organization?.emailDomain &&
      domain.toLowerCase() !==
        team?.organization?.emailDomain.toLowerCase()
    ) {
      alert(
        "error",
        `The email must have an @${team.organization.emailDomain} domain.`,
      );
      return false;
    }
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    alert("error", `${email} is not a valid email.`);
    return false;
  }

  return true;
};

const AddUsers = ({
  newUserEmails,
  setNewUserEmails,
  team = { uuid: "", users: [] },
  isNewTeam,
}) => {
  const [users, setUsers] = useState(newUserEmails || []);
  const [inputValue, setInputValue] = useState("");
  const [enabled] = useDarkMode();

  useEffect(() => {
    setUsers(newUserEmails);
  }, [newUserEmails]);

  const textFieldStyles = useMemo(
    () => ({
      width: "100%",
      resize: "none",
      appearance: "none",
      border: "1.5px solid",
      borderColor: "#6366f1",
      backgroundColor: enabled ? "#1f2937" : "white",
      color: enabled ? "white" : "#1f2937",
      borderRadius: "2px",
      "& .MuiInputBase-input": {
        color: enabled ? "white" : "#1f2937",
      },
      "& .MuiOutlinedInput-input": {
        color: enabled ? "white" : "#1f2937",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: enabled ? "#1f2937" : "white",
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "transparent",
        },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "transparent",
        },
      "& .MuiAutocomplete-clearIndicator": {
        color: enabled ? "white" : "#1f2937",
      },
      "&::placeholder": {
        color: "#9ca3af",
      },
      "&:focus": {
        outline: "none",
        zIndex: 10,
      },
    }),
    [enabled],
  );

  const chipStyles = useMemo(
    () => ({
      margin: "2px",
      borderColor: enabled ? "#374151" : "#f3f4f6",
      backgroundColor: enabled ? "#374151" : "#f3f4f6",
      color: enabled ? "white" : "#1f2937",
      "&:hover": {
        borderColor: "#6366f1",
      },
      "& .MuiChip-deleteIcon": {
        color: "#9ca3af",
        "&:hover": {
          color: "#6366f1",
        },
      },
    }),
    [enabled],
  );

  const handleAddUser = (event, newValue) => {
    const teamUsersArray =
      team?.users?.map((user) => user.email) || [];
    const validEmails = newValue?.filter((email) =>
      isValidEmail(email, teamUsersArray, team, isNewTeam),
    );
    setUsers(validEmails);
    setNewUserEmails(validEmails);
  };

  const handleBlur = () => {
    if (inputValue.trim()) {
      const teamUsersArray =
        team?.users?.map((user) => user.email) || [];
      if (isValidEmail(inputValue, teamUsersArray, team, isNewTeam)) {
        const updatedUsers = [...users, inputValue];
        setUsers(updatedUsers);
        setNewUserEmails(updatedUsers);
        setInputValue("");
      }
    }
  };

  return (
    <>
      <h5 className="text-lg">
        Add the <strong>email</strong> of the new team members.
      </h5>
      <p className="mb-2 text-gray-400 dark:text-gray-500 text-xs">
        Hit the 'Enter' key after typing an email to add it to the
        list.
      </p>
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={users}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={handleAddUser}
        onBlur={handleBlur}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              key={index}
              sx={chipStyles}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Add user"
            sx={textFieldStyles}
          />
        )}
      />
      <p className="mt-2 text-gray-400 dark:text-gray-500 text-xs">
        Existing users will be added, and new users will be sent an
        email with credentials for their new account!
      </p>
    </>
  );
};

export default AddUsers;
