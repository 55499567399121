import { gql } from "@apollo/client";

const GET_EXISTING_USERS = gql`
  query existingUsers($emails: [String!]) {
    existingUsers(emails: $emails) {
      existingUsers {
        teams {
          name
        }
        account {
          uuid
          accountType
          stripeCustomerId
          isSubscribed
        }
        email
        firstName
        lastName
        uuid
      }
      newUsers
      deactivatedUsers
    }
  }
`;
export default GET_EXISTING_USERS;
