import { gql } from "@apollo/client";

export const TRANSFER_NOTES = gql`
  mutation transferNoteOwnership(
    $noteUuids: [String]
    $newOwnerUuid: String
    $newTeamUuid: String
  ) {
    transferNoteOwnership(
      noteUuids: $noteUuids
      newOwnerUuid: $newOwnerUuid
      newTeamUuid: $newTeamUuid
    ) {
      ok
      failedNotes
    }
  }
`;
