import { useMutation } from "@apollo/client";
import { FormControl, MenuItem, Select } from "@mui/material";
import { captureException } from "@sentry/react";
import moment from "moment";
import React from "react";

import { submitTestingSampleAudio } from "../../api/UploadAudio.js";
import { ENVIRONMENT, testEnvs, testUsers } from "../../constants.js";
import { useLocalStorage, useTeam } from "../../hooks";
import { alert } from "../common/Alert.js";
import { BasicButton } from "../common/buttons/BasicButton.js";
import { CREATE_QUICK_NOTE } from "../notes/edit/api/mutations.js";

const callbackAudio = require("../../assets/other/callback.wav");
const dentalRecordAudio = require("../../assets/other/dental_record.wav");
const dictationAudio = require("../../assets/other/dictation.wav");
const longMedicalRecordAudio = require("../../assets/other/dobby-incomplete-extended.wav");
const medicalRecordAudio = require("../../assets/other/medical_record.wav");
const multipetAudio = require("../../assets/other/multipet.wav");
const packmodeAudio = require("../../assets/other/packmode.wav");
const recapAudio = require("../../assets/other/recap.wav");
const ultrasoundAudio = require("../../assets/other/ultrasound.wav");

export const testRecordingsData = [
  {
    jobType: "medical_record",
    value: "medical_record",
    name: "Test Medical Record (SOAP)",
    audioUrl: medicalRecordAudio,
  },
  {
    jobType: "multipet",
    value: "multipet",
    name: "Test Multipet (SOAP)",
    audioUrl: multipetAudio,
  },
  {
    jobType: "packmode",
    value: "packmode",
    name: "Test Packmode (SOAP)",
    audioUrl: packmodeAudio,
  },
  {
    jobType: "medical_record",
    value: "long_medical_record",
    name: "Test Long Messy Medical Record",
    audioUrl: longMedicalRecordAudio,
  },
  {
    jobType: "dictation",
    value: "dictation",
    name: "Test Dictation",
    audioUrl: dictationAudio,
  },
  {
    jobType: "recap",
    value: "recap",
    name: "Test Recap",
    audioUrl: recapAudio,
  },
  {
    jobType: "callback",
    value: "callback",
    name: "Test Callback",
    audioUrl: callbackAudio,
  },
  {
    jobType: "dental_record",
    value: "dental_record",
    name: "Test Dental Record",
    audioUrl: dentalRecordAudio,
  },
  {
    jobType: "abdominal_ultrasound",
    value: "abdominal_ultrasound",
    name: "Test Ultrasound",
    audioUrl: ultrasoundAudio,
  },
];

const throwUnhandledError = () => {
  throw new Error("Unhandled test error from TestRecordings.js");
};

const throwHandledError = () => {
  try {
    throw new Error("Handled test error from TestRecordings.js");
  } catch (error) {
    captureException(error);
  }
};

export const SubmitTestRecordingButton = ({
  onClick,
  text = "Submit Selected",
}) => {
  return (
    <BasicButton
      importance="secondary"
      styles="ml-2"
      onClick={onClick}
    >
      {text}
    </BasicButton>
  );
};

export const TestRecordTypeDropdown = ({
  selectedTestRecordType,
  setSelectedTestRecordType,
  testRecordings,
}) => {
  return (
    <FormControl sx={{ width: 250 }} size="small">
      <Select
        sx={[
          {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          {
            fontSize: "14px",
            color: "#f9fafb",
            backgroundColor: "#f1807e",
            borderRadius: "8px",
          },
        ]}
        id="test-select"
        value={selectedTestRecordType}
        onChange={(event) => {
          setSelectedTestRecordType(event.target.value);
        }}
      >
        {testRecordings.map((recording) => {
          return (
            <MenuItem
              key={recording.value}
              sx={{ fontSize: "14px" }}
              value={recording.value}
            >
              <div>{recording.name}</div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export function TestRecordings({ data }) {
  const [createQuickNote] = useMutation(CREATE_QUICK_NOTE);

  // uselocal storage to persist testrecord-type
  const [selectedTestRecordType, setSelectedTestRecordType] =
    useLocalStorage("testrecord-type", "medical_record");

  const { currentTeam } = useTeam();
  const createNewNote = async (
    audioStorageLink,
    storageObjectName,
  ) => {
    let jobType = JSON.parse(localStorage.getItem("testrecord-type"));

    return await createQuickNote({
      variables: {
        noteInput: {
          audioStorageLink: audioStorageLink,
          storageObjectName: storageObjectName,
          createdAt: moment().format(),
          lastUpdatedAt: moment().format(),
          jobType: jobType,
          teamUuid: currentTeam?.uuid,
        },
      },
    }).then(() => {
      alert("success", "Recording has been uploaded!");
    });
  };

  const submitTestRecording = () => {
    // using the selected value from the dropdown, search the testRecordings array
    // for the correct jobType and audioUrl
    let recording = testRecordingsData.find(
      (recording) => recording.value == selectedTestRecordType,
    );

    const { jobType, audioUrl } = recording;

    localStorage.setItem("testrecord-type", JSON.stringify(jobType));

    submitTestingSampleAudio({
      createNewNote,
      audioUrl,
    });
  };

  if (
    !(
      testEnvs.includes(ENVIRONMENT) ||
      testUsers.includes(data?.user?.email)
    )
  ) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="flex flex-col ">
          <TestRecordTypeDropdown
            selectedTestRecordType={selectedTestRecordType}
            setSelectedTestRecordType={setSelectedTestRecordType}
            testRecordings={testRecordingsData}
          />
        </div>
        <div className="flex flex-col ">
          <SubmitTestRecordingButton onClick={submitTestRecording} />
        </div>
      </div>
      <div className="flex flex-row mt-2">
        <BasicButton onClick={throwHandledError}>
          Throw Handled Error
        </BasicButton>
        <BasicButton styles="ml-2" onClick={throwUnhandledError}>
          Throw Unhandled Error
        </BasicButton>
      </div>
    </div>
  );
}
