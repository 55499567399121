import { gql } from "@apollo/client";

const ADD_USERS_TO_TEAM = gql`
  mutation addUsersToTeam($addUsersInput: AddUsersToTeamInput!) {
    addUsersToTeam(addUsersInput: $addUsersInput) {
      ok
      addUsers {
        existing {
          success
          email
        }
        newUsers {
          success
          email
        }
      }
    }
  }
`;

export default ADD_USERS_TO_TEAM;
