import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {
  AccountProvider,
  AuthProvider,
  NoteUsageProvider,
  PreferencesProvider,
  WatchNoteUpdatesProvider,
} from "@hooks";
import { CurrentUserProvider } from "@hooks/use-current-user";
import { PreventRecordingProvider } from "@hooks/use-prevent-recording-interruptions";
import { TeamProvider } from "@hooks/use-team";
import { createTheme, ThemeProvider } from "@mui/material";
import { captureException } from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import {
  INTERCOM_ENABLED,
  INTERCOM_ID,
  SCRIBENOTE_GRAPHQL,
} from "../../constants.js";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Inter",
    },
  },
});
/** BEGIN APOLLO CLIENT CONFIG */
const httpLink = createHttpLink({
  credentials: "include",
});

// Manage global auth headers through authLink
const authLink = setContext(async (_, { headers }) => {
  // Need to use the csrf session token that comes back on
  // Auth steps and include it in all requests.

  const csrf_token = localStorage.getItem("accessCsrf");

  // Used for rerouting to the correct API host
  // Interfaces through localstorage to allow for proper ordering of
  // hooks + context + launchdarkly user identification (needs auth to be set first)

  // don't use till csrf issue fixed
  // const URI =
  //   localStorage.getItem("SCRIBENOTE_API_HOST") + "/graphql" ||
  //   SCRIBENOTE_GRAPHQL;

  return {
    uri: SCRIBENOTE_GRAPHQL,
    headers: {
      ...headers,
      "X-CSRF-Token": csrf_token,
      "X-SCRIBENOTE-USER-DEVICE": "web",
    },
  };
});

// Manage global graphQLErrors through errorlink
// This works with the Error Context to broadcast messages to
// our error component. Messages are pushed into the errors list,
// which is passed to error context
const errorLink = onError(({ graphQLErrors, networkError }) => {
  function kickEmOut(message, locations, path) {
    // Check if the page has already been reloaded
    if (localStorage.getItem("already_reloaded")) {
      // If it has, stop the reload loop
      return;
    }
    captureException(new Error(message), {
      extra: {
        locations,
        path,
      },
    });
    // Set the flag in localStorage to indicate that the page has reloaded
    localStorage.setItem("already_reloaded", "true");

    // Unauthenticate the user in the event that auth is failing.
    localStorage.removeItem("user_first_name");
    localStorage.removeItem("is_user_authenticated");
    localStorage.removeItem("accessCsrf");
    localStorage.removeItem("auth_expiry");
    localStorage.removeItem("org_uuid");
    localStorage.removeItem("currentTeam");
    localStorage.removeItem("user_uuid");

    // Reload the page
    window.location.reload();
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      if (message === "Signature verification failed") {
        kickEmOut(message, locations, path);
      } else if (message === "Signature has expired") {
        kickEmOut(message, locations, path);
      } else if (
        message === "400 Bad Request: The CSRF tokens do not match."
      ) {
        kickEmOut(message, locations, path);
      } else if (
        message === "400 Bad Request: The CSRF token is invalid."
      ) {
        kickEmOut(message, locations, path);
      } else if (
        message === "400 Bad Request: The CSRF token has expired."
      ) {
        kickEmOut(message, locations, path);
      } else if (
        message ===
        'Missing JWT in cookies or headers (Missing cookie "access_token_cookie"; Missing Authorization Header)'
      ) {
        kickEmOut(message, locations, path);
      } else if (
        message === "Token has been revoked" &&
        window.location.pathname === "/account"
      ) {
        window.location.reload();
      }

      console.log(message);
      // The CSRF session token is missing.
      // Do the same for other error types
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const mergePaginatedNotes = (
  existing,
  incoming,
  { args: { offset = 0 } },
) => {
  // Slicing is necessary because the existing data is
  // immutable, and frozen in development.

  // only do this on notes
  if (incoming?.notes) {
    const merged = existing ? existing.notes.slice(0) : [];
    for (let i = 0; i < incoming.notes.length; ++i) {
      merged[offset + i] = incoming.notes[i];
    }
    return {
      ...incoming,
      notes: merged,
    };
  } else {
    return incoming;
  }
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        dashboardInboxNotes: {
          keyArgs: ["$teamUuid"],
          merge: mergePaginatedNotes,
        },
        inboxNotes: {
          keyArgs: [
            "$startDate",
            "$endDate",
            "$teamUuid",
            "$userUuids",
            "$filter",
          ],
          merge: mergePaginatedNotes,
        },
      },
    },
  },
});

/** END APOLLO CLIENT CONFIG */

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: cache,
  credentials: "include",
});

// AccountProvider MUST wrap AuthProvider since AuthProvider requires the
// useAccount() hook
export default function Providers({ children }) {
  return (
    <>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <IntercomProvider
            appId={INTERCOM_ID}
            shouldInitialize={INTERCOM_ENABLED}
            autoBoot
          >
            <AccountProvider>
              <AuthProvider>
                <CurrentUserProvider>
                  <WatchNoteUpdatesProvider>
                    <TeamProvider>
                      <NoteUsageProvider>
                        <PreventRecordingProvider>
                          <PreferencesProvider>
                            <ThemeProvider theme={theme}>
                              {children}
                            </ThemeProvider>
                          </PreferencesProvider>
                        </PreventRecordingProvider>
                      </NoteUsageProvider>
                    </TeamProvider>
                  </WatchNoteUpdatesProvider>
                </CurrentUserProvider>
              </AuthProvider>
            </AccountProvider>
          </IntercomProvider>
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
}
