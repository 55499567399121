import { useMutation } from "@apollo/client";
import { useTeam } from "@hooks/use-team.js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DELETE_NOTE } from "./api/mutations.js";
import EditableNoteFormWrapper from "./EditableNoteFormWrapper.js";
import {
  AutosaveProvider,
  UploadProgressProvider,
  useApplyTemplate,
} from "../../../hooks";
import { alert } from "../../common/Alert.js";
import { ConfirmModal } from "../../common/modals/PopupModals.js";

export function EditableNote(props) {
  const {
    note,
    isSuperSOAP = false,
    isScribeEdit = false,
    shouldBillUserForNote,
    status,
  } = props;
  let isQuicknote =
    props.isQuicknote ||
    (note?.quickNote !== null && note?.quickNote !== undefined);
  let patientUUID = props?.patientUUID || note?.patient?.uuid;

  const [shouldShowDeleteNoteModal, setShouldShowDeleteNoteModal] =
    useState(false);

  const [shouldShowCheckoutModal, setShouldShowCheckoutModal] =
    useState(false);

  const { currentTeam } = useTeam();

  const noteUUID = note?.uuid;
  const teamUUID = note?.team?.uuid || currentTeam?.uuid;
  const [deleteNote] = useMutation(DELETE_NOTE);
  const navigate = useNavigate();

  const { template } = useApplyTemplate({ noteUuid: noteUUID });

  useEffect(() => {
    if (status?.ok == "false") {
      setShouldShowCheckoutModal(true);
    }
  }, [status]);

  function hideModal() {
    setShouldShowDeleteNoteModal(false);
    setShouldShowCheckoutModal(false);
  }

  async function handleDeleteNote() {
    try {
      await deleteNote({ variables: { noteUuid: noteUUID } });
      alert("success", "Note deleted.");
      navigate("/dashboard");
    } catch (e) {
      alert(
        "error",
        "Failed to delete note. Are you trying to delete a note you don't own?",
      );
    } finally {
      setShouldShowDeleteNoteModal(false);
    }
  }

  function handleEditNoteCancel() {
    navigate("/dashboard");
  }

  return (
    <div>
      <ConfirmModal
        shouldShow={shouldShowCheckoutModal}
        hideModal={hideModal}
        title="Edit this note?"
        confirmText="Yes"
        confirmAction={hideModal}
        cancelAction={handleEditNoteCancel}
      >
        <h5>
          Are you sure you&apos;d like to edit this note? It is
          currently checked out by:{" "}
          <strong>
            {status?.user?.firstName} {status?.user?.lastName}
          </strong>
          . WARNING: You may lose your changes.
        </h5>
      </ConfirmModal>

      <ConfirmModal
        shouldShow={shouldShowDeleteNoteModal}
        hideModal={hideModal}
        title="Delete this note?"
        confirmText="Delete"
        confirmAction={handleDeleteNote}
        cancelAction={hideModal}
      >
        <h5>
          Are you sure you&apos;d like to{" "}
          <strong>delete this note</strong>?
        </h5>
      </ConfirmModal>

      <UploadProgressProvider>
        <AutosaveProvider>
          <EditableNoteFormWrapper
            note={note}
            template={template}
            isScribeEdit={isScribeEdit}
            shouldBillUserForNote={shouldBillUserForNote}
            isSuperSOAP={isSuperSOAP}
            isQuicknote={isQuicknote}
            patientUUID={patientUUID}
            noteUUID={noteUUID}
            teamUUID={teamUUID}
            shouldShowDeleteNoteModal={shouldShowDeleteNoteModal}
            setShouldShowDeleteNoteModal={
              setShouldShowDeleteNoteModal
            }
          />
        </AutosaveProvider>
      </UploadProgressProvider>
    </div>
  );
}

EditableNote.propTypes = {
  note: PropTypes.object,
  isSuperSOAP: PropTypes.bool,
  isQuicknote: PropTypes.bool,
  patientUUID: PropTypes.string,
};
