export const mapTeamData = (data) => {
  const team = data.team;
  const mappedData = {
    uuid: team.uuid,
    name: team.name,
    orgUuid: team.organization?.uuid,
    users: team.userTeams.map((userTeam) => ({
      uuid: userTeam.user.uuid,
      email: userTeam.user.email,
      numAppointmentsUsed: userTeam.user.numAppointmentsUsed,
      firstName: userTeam.user.firstName,
      lastName: userTeam.user.lastName,
      roleId: userTeam.roleId,
      isAdmin: userTeam.isAdmin,
    })),
  };
  return mappedData;
};
