import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoadMore = ({ itemStyle, incrementOffset, loadingMore }) => {
  return (
    <div style={itemStyle} className="my-4">
      <button
        className="outline-none w-full rounded-full shadow-md bg-indigo-500 hover:bg-indigo-600 self-center py-1.5 px-3 text-white font-medium focus:outline-none transition-all"
        onClick={() => incrementOffset()}
      >
        {loadingMore ? (
          <FontAwesomeIcon
            icon={faSpinner}
            color="white"
            className="animate-spin"
          />
        ) : (
          "Load More..."
        )}
      </button>
    </div>
  );
};
export default LoadMore;
