import {
  faHistory,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextareaAutosize } from "@mui/base";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import AutosaveState from "./AutosaveState";
import { NoteCreatedAtTimer } from "./NoteCreatedAtTimer";
import NoteFormOptions from "./NoteFormOptions";
import SomethingWrongButton from "./SomethingWrongButton";
import { UseTemplateButton } from "./UseTemplateButton.js";
import HeadingWrapper from "../../../common/HeadingWrapper";
import { AudioPlayer } from "../../../notebook/note/AudioPlayer";
import NoteStatus from "../../../notebook/note/NoteStatus";
import PatientTitle from "../../../notebook/PatientTitle";

function Divider({ children }) {
  return (
    <div className="justify-self-center sticky top-14 z-20 bg-gradient-to-b from-white via-white dark:from-gray-800 dark:via-gray-800 w-full my-5">
      {children}
    </div>
  );
}

export function ExistingNoteFields({
  note = null,
  isScribeEdit = false,
  isSuperSOAP,
  control,
}) {
  const [isTitleFocused, setIsTitleFocused] = useState(false);

  const titleRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const isOnboarding = location.pathname === "/dashboard";

  if (note === null) {
    return null;
  }

  return (
    <Divider>
      <div className="flex flex-row items-center my-1 space-x-4">
        <div className="flex flex-1 flex-col md:flex-row md:items-center md:space-x-3">
          <div className="flex flex-row items-center space-x-3">
            <h2 className="text-left flex-1 text-gray-700 dark:text-gray-300">
              {note?.patient ? (
                <HeadingWrapper
                  heading={
                    <div>
                      Note for{" "}
                      <PatientTitle
                        sex={note?.patient?.sex}
                        species={note?.patient?.species}
                        name={note?.patient?.name}
                        patientUUID={note?.patient?.uuid}
                        lastNameOfOwner={
                          note?.patient?.lastNameOfOwner
                        }
                        size="lg"
                      />
                    </div>
                  }
                ></HeadingWrapper>
              ) : note?.jobTypeName ? (
                note?.jobTypeName
              ) : (
                "Quicknote"
              )}
            </h2>
            <NoteStatus note={note} />
            {note?.isAutoSoap &&
            (note?.isMarkedAsReview || note?.isMarkedAsFailed) ? (
              <SomethingWrongButton note={note} />
            ) : null}
          </div>

          <div className="flex-1 h-0.5 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700"></div>
          <div className="flex flex-row items-center space-x-2 divide-x dark:divide-gray-700 my-2 md:my-0">
            {note?.isAutoSoap && (
              <p className="text-sm text-gray-400 dark:text-gray-500 text-center">
                <FontAwesomeIcon
                  className="mr-1"
                  icon={faHourglass}
                  size="sm"
                />
                {note?.timeToProcess
                  ? `Processed in ${note?.timeToProcess}s`
                  : "Processing time unavailable"}
              </p>
            )}
            {!isScribeEdit && !isOnboarding && (
              <>
                <div className="pl-2 text-center">
                  <AutosaveState />
                </div>

                <button
                  className="pl-2 text-sm text-gray-400 dark:text-gray-500 transition-all hover:text-gray-600 dark:hover:text-gray-600 focus:outline-none text-center"
                  onClick={() => {
                    navigate(`/audit_trail/note/${note?.uuid}`);
                  }}
                >
                  <FontAwesomeIcon icon={faHistory} size="sm" /> See
                  Past Revisions
                </button>
              </>
            )}
            {note?.user && (
              <div className="pl-2 text-sm text-gray-400 dark:text-gray-500 text-center">
                Owned by {note.user.firstName} {note.user.lastName}
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <AudioPlayer
          noteUuid={note?.uuid}
          isTagged={note?.isTagged}
          shouldShowAddButton={!isSuperSOAP}
        />
      </div>
      <div className="flex flex-row items-start space-x-2 pb-6 mt-3">
        <Tooltip title={note?.title} arrow placement="top">
          <div className="w-full">
            <Controller
              render={({ field }) => (
                <TextareaAutosize
                  ref={titleRef}
                  style={
                    isTitleFocused ? { height: 80 } : { height: 40 }
                  }
                  maxRows={1}
                  placeholder={"📝 Note Title (optional)"}
                  defaultValue={note?.title}
                  className="w-full resize-none appearance-none relative block px-2 py-1 border-2 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:border-2 focus:border-indigo-300 dark:focus:border-indigo-300 focus:z-10 text-xs md:text-sm"
                  {...field}
                  onFocus={() => setIsTitleFocused(true)}
                  onBlur={() => {
                    setIsTitleFocused(false);
                  }}
                />
              )}
              control={control}
              name={"title"}
            />
          </div>
        </Tooltip>

        <Tooltip
          title="Type in a new date or click the calendar icon to edit the date and time the note was made."
          arrow
          placement="top"
        >
          <div className="lg:min-w-xl">
            <NoteCreatedAtTimer />
          </div>
        </Tooltip>

        {!isScribeEdit && !isOnboarding && (
          <>
            <NoteFormOptions isAutoNote={note?.isAutoSoap} />

            {!note?.isAutoSoap && (
              <UseTemplateButton
                noteUUID={note?.uuid}
                isAutoSoap={note?.isAutoSoap}
              />
            )}
          </>
        )}
      </div>
    </Divider>
  );
}

ExistingNoteFields.propTypes = {
  note: PropTypes.object,
  isQuicknote: PropTypes.bool,
};
