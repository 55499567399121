import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

import { addSelectedNotes, removeSelectedNotes } from "./utils";
import { useNoteMultiselectContext } from "../../../hooks";

export function CheckBox({
  checkBoxItemUuid,
  icon,
  isSelected,
  isPatient,
}) {
  const {
    selectedNoteUuids,
    setSelectedNoteUuids,
    numSelectedNotes,
    setNumSelectedNotes,
    numPatientNotes,
    setNumPatientNotes,
  } = useNoteMultiselectContext();

  return (
    <div className="relative flex flex-row items-center justify-center">
      <input
        onClick={() => {
          if (!isSelected) {
            addSelectedNotes({
              noteUuid: checkBoxItemUuid,
              selectedNoteUuids,
              setSelectedNoteUuids,
              numSelectedNotes,
              setNumSelectedNotes,
              numPatientNotes,
              setNumPatientNotes,
              isPatient,
            });
          } else {
            removeSelectedNotes({
              noteUuid: checkBoxItemUuid,
              selectedNoteUuids,
              setSelectedNoteUuids,
              numSelectedNotes,
              setNumSelectedNotes,
              numPatientNotes,
              setNumPatientNotes,
              isPatient,
            });
          }
        }}
        type="checkbox"
        defaultChecked={isSelected}
        className="appearance-none rounded-xl bg-white dark:bg-gray-700 border-2 border-indigo-500 checked:bg-indigo-500 dark:checked:bg-indigo-500 checked:border-transparent h-6 w-6 cursor-pointer transition-all"
      />
      <div
        className={`absolute flex flex-row items-center justify-center pointer-events-none ${
          isSelected ? "text-white" : "text-indigo-500/30"
        }`}
      >
        <FontAwesomeIcon icon={icon} size="xs" />
      </div>
    </div>
  );
}

CheckBox.propTypes = {
  setSelectedCheckbox: PropTypes.func,
  removeSelectedCheckbox: PropTypes.func,
  checkBoxItemUuid: PropTypes.string,
};
