import { useMutation } from "@apollo/client";
import {
  faChevronDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTeam } from "@hooks/use-team";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";

import { ChangeStepButton } from "./FirstLoginTour";
import { AppointmentTranscript } from "../../../assets/data/Transcripts";
import AppointmentAudio from "../../../assets/other/onboarding-appt-example.wav";
import Twemoji from "../../../Twemoji";
import { CREATE_QUICK_NOTE } from "../../notes/edit/api/mutations";

function OnboardingAudioTranscript({ transcript }) {
  return (
    <Accordion
      square={false}
      defaultExpanded={true}
      sx={{
        "&::before": {
          backgroundColor: "transparent",
        },
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
        borderRadius: "5px",
        backgroundColor: "rgba(255,255,255,0.1)",
        marginTop: "10px",
      }}
    >
      <AccordionSummary
        sx={{ borderTop: "none" }}
        expandIcon={
          <FontAwesomeIcon
            className="dark:text-gray-300"
            icon={faChevronDown}
          />
        }
      >
        <h4 className="text-sm">Transcript</h4>
      </AccordionSummary>
      <AccordionDetails>
        <h5 className="text-sm h-48 overflow-y-auto">{transcript}</h5>
      </AccordionDetails>
    </Accordion>
  );
}

function OnboardingAudioPlayer({ audioLink }) {
  return (
    <audio className="w-full my-4 rounded-lg" controls>
      <source src={audioLink} type="audio/wav" />
      Your browser does not support the <code>audio</code> element.
      <a href={audioLink}>
        Click here to download the full appointment audio
      </a>
    </audio>
  );
}

export default function RecordOnboardingStep({
  handleSubmission,
  isSubmitting,
  goToPreviousStep,
}) {
  const [isFailed, setIsFailed] = useState(false);

  const [createQuickNote] = useMutation(CREATE_QUICK_NOTE);
  const handleAppointmentSubmit = async () => {
    try {
      await handleSubmission(createAppointment);
    } catch (error) {
      setIsFailed(true);
    }
  };
  const { currentTeam } = useTeam();

  async function createAppointment(
    audioStorageLink,
    storageObjectName,
  ) {
    const result = await createQuickNote({
      variables: {
        noteInput: {
          subjective:
            "Owner reports no concerns with Julius. Patient is eating well and using the litter box appropriately. Owner attempts to brush Julius' teeth at home but has difficulty due to his feistiness.",
          objective: `Temperature: Not mentioned
Eyes: Clear
Ears: Clear
Oral/Dental: Healthy teeth
Cardiovascular: Normal heart rate
Respiratory: Not mentioned
Neurological: Not mentioned
Musculoskeletal: Normal mobility, able to jump well
Lymphatic: Normal
Gastrointestinal: Not mentioned
Reproductive/Urinary: Not mentioned
Fur/Skin: Not mentioned
Heart rate: Normal
Weight: Not mentioned
Respiratory Rate: Not mentioned
Capillary Refill Time: Not mentioned
Body Condition Score: Not mentioned`,
          assessment: "Healthy. Stable for vaccines.",
          plan: `1. Administer rabies vaccine in the right hind leg under the skin (three-year vaccine).
2. Administer feline viral vaccine in the left hind leg under the skin (three-year vaccine).
3. Provide a lickable cat treat as a distraction during vaccination.
4. Complete nail trim for Julius.`,
          audioStorageLink: audioStorageLink,
          storageObjectName: storageObjectName,
          isOnboardingDemo: true,
          shouldUseGenericObjective: true,
          title: "Appointment example from the tutorial!",
          createdAt: moment().format(),
          lastUpdatedAt: moment().format(),
          transcription: AppointmentTranscript,
          teamUuid: currentTeam?.uuid,
        },
      },
    });
    return result;
  }

  function renderAppointmentExample() {
    return (
      <div className="flex flex-col items-center flex-1 rounded-xl bg-gray-50 dark:bg-gray-700/50 p-6">
        <h4 className="mb-2">Example Recording of an Appointment</h4>
        <OnboardingAudioPlayer audioLink={AppointmentAudio} />
        <OnboardingAudioTranscript
          transcript={AppointmentTranscript}
        />
        <button
          disabled={isSubmitting}
          className="mt-4 text-xs md:text-sm text-white rounded-full py-2 px-3 bg-indigo-500 hover:bg-indigo-600 disabled:bg-gray-400 transition-colors"
          onClick={handleAppointmentSubmit}
        >
          {isSubmitting ? (
            <span>
              <FontAwesomeIcon
                icon={faSpinner}
                color="white"
                className="animate-spin mr-1"
              />
              Processing...
            </span>
          ) : (
            "Submit Transcript"
          )}
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center text-center space-y-4">
        <h3 className="text-center md:w-[600px]">
          Let&#39;s make a Medical Record! <Twemoji emoji="🩺" />
        </h3>
        <p className="max-w-2xl">
          You can pull out your phone and try a real recording using
          the Scribenote app, or simply submit a recording example
          below. This is what we&#39;ll turn into a SOAP note!
        </p>
        <p>
          Fun fact: This is the same transcript from{" "}
          <a
            className="text-indigo-500 underline"
            href="https://www.youtube.com/watch?v=3SsknGy7Dio"
            target="_blank"
            rel="noreferrer"
          >
            this demo video
          </a>
          !
        </p>
      </div>

      <div className="flex flex-col lg:flex-row items-center space-y-10 lg:space-y-0 lg:space-x-10 pt-10 max-w-xl">
        {renderAppointmentExample()}
      </div>

      {isFailed && (
        <button
          className="text-xs md:text-sm text-indigo-500 underline"
          onClick={() => location.reload()}
        >
          Processing failed. Click here to try again.
        </button>
      )}

      <ChangeStepButton onClick={goToPreviousStep} text={"← Back"} />
    </>
  );
}
