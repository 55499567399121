import React from "react";

export const BasicButton = ({
  styles = "",
  importance,
  dataCy = null,
  ...props
}) => {
  const button = (
    <button
      data-cy={dataCy}
      className={`${styles} ${
        importance === "secondary"
          ? "border-indigo-500 disabled:bg-gray-400 disabled:border-gray-400 disabled:text-white disabled:dark:text-white text-indigo-500 hover:text-white hover:bg-indigo-500 "
          : "bg-indigo-500 border-indigo-500 hover:bg-indigo-700 text-white transition-all focus:outline-none disabled:bg-gray-400 disabled:border-gray-400 disabled:dark:border-gray-600 disabled:dark:bg-gray-600"
      } flex-grow-0 flex flex-row items-center self-center border rounded-full px-4 py-2 font-medium text-sm outline-none transition-all`}
      {...props}
    >
      {props.children}
    </button>
  );
  return button;
};
