import { gql } from "@apollo/client";

const GET_ORGANIZATION_BY_UUID = gql`
  query GetOrganizationByUuid($uuid: String!) {
    organization(uuid: $uuid) {
      name
      uuid
      createdAt
      location
      numTeams
      numUsers
      teams {
        numUsers
        uuid
        name
        createdAt
        location
        users {
          uuid
          firstName
          lastName
        }
      }
      admins {
        uuid
        firstName
        lastName
      }
    }
  }
`;

export default GET_ORGANIZATION_BY_UUID;
