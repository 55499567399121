import { useQuery } from "@apollo/client";
import {
  faUserPlus,
  faUsers,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks";
import { useState } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { mapTeamData } from "./utils";
import PartyScribbles from "../../../assets/imgs/party-scribbles.png";
import GET_USER_TEAMS from "../../../graphql/queries/GetUserTeams";
import { BasicButton } from "../../common/buttons/BasicButton";
import HeadingWrapper from "../../common/HeadingWrapper";
import Loading from "../../common/loading/Loading";
import PageTitle from "../../layout/PageTitle";
import { EditExistingTeamMembers } from "../EditExistingTeamMembers";
import TeamModal from "../TeamModal";

export default function TeamManagement() {
  // State
  const [isShowingAddMembersModal, setIsShowingAddMembersModal] =
    useState(false);
  const [team, setTeam] = useState({
    uuid: "",
    users: [],
    orgUuid: "",
    name: "",
  });

  // Hooks
  const { teamUuid } = useParams();
  const { orgUuid, userTeams, loadingUserData, userUuid } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Constants
  const userTeamUUIDs = userTeams?.map((team) => team.uuid);
  const isUserOnTeam = userTeamUUIDs?.includes(team?.uuid);
  const isTeamInOrganization = orgUuid === team?.orgUuid;
  const isCurrentUserAnAdmin = team?.users?.find(
    (user) => user.uuid === userUuid,
  )?.isAdmin;

  // Queries
  const { refetch, loading } = useQuery(GET_USER_TEAMS, {
    variables: { uuid: teamUuid },
    fetchPolicy: "no-cache",
    skip: !teamUuid,
    onCompleted: (data) => {
      setTeam(mapTeamData(data));
    },
  });

  // Functions
  const handleBackClick = () => {
    const queryParams = new URLSearchParams(location.search);
    const returnPage = orgUuid ? "organization" : "teams";
    navigate(`/${returnPage}?${queryParams.toString()}`);
  };

  const hideAddMembersModal = () => {
    setIsShowingAddMembersModal(false);
  };

  const handleRefetch = () => {
    refetch({ variables: { uuid: teamUuid } }).then(({ data }) => {
      if (data) {
        setTeam(mapTeamData(data));
      }
    });
  };

  if (loading || loadingUserData) {
    return <Loading shouldShow={loading || loadingUserData} />;
  }

  // Only access this team if you belong to the team
  // Or if you are an admin of the team's organization
  // And your userTeams array has been loaded
  if (
    !isUserOnTeam &&
    !isCurrentUserAnAdmin &&
    !isTeamInOrganization &&
    Array.isArray(userTeams)
  ) {
    return (
      <div className="flex flex-col h-[600px] items-center justify-center">
        <div className="flex flex-col items-center space-y-10 bg-yellow-500/10 p-10 rounded-xl border border-yellow-200">
          <h2>Whoops, we can&apos;t take you there.</h2>
          <h5 className="max-w-xl text-center">
            You don't have permission to view this team.
          </h5>
          <a href="/teams">
            <h4 className="underline text-indigo-500">
              Click here to return to your teams.
            </h4>
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <TeamModal
        isOpen={isShowingAddMembersModal}
        onClose={hideAddMembersModal}
        team={team}
        refetch={handleRefetch}
        orgUuid={orgUuid}
      />
      <PageTitle
        title={`Manage ${team?.name ? team?.name : "Team"}`}
      />
      {team && (
        <>
          <TeamHeaderInfo team={team} />
          <div className="flex flex-row items-center justify-end space-x-2">
            <BasicButton
              importance="secondary"
              onClick={handleBackClick}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />{" "}
              View Other Teams
            </BasicButton>
            {
              // Only show the add members button if the user is an admin
              isCurrentUserAnAdmin && (
                <BasicButton
                  onClick={() => setIsShowingAddMembersModal(true)}
                  importance="primary"
                >
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    className="mr-2"
                  />{" "}
                  Add Team Members
                </BasicButton>
              )
            }
          </div>
          <div className="pb-4">
            <HeadingWrapper heading={<h3>Current Team Members</h3>} />
            {team?.users?.length === 0 && isCurrentUserAnAdmin ? (
              <div
                className="flex flex-col items-center bg-gray-100/70 dark:bg-gray-700/30 border-2 border-dashed border-gray-50 dark:border-gray-700/50 rounded-lg py-10 px-2 w-full my-4 dark:hover:bg-gray-700/50 transition-all cursor-pointer hover:bg-indigo-100 hover:border-indigo-200"
                onClick={() => setIsShowingAddMembersModal(true)}
              >
                <img
                  className="flex self-center"
                  width="100px"
                  src={PartyScribbles}
                  alt="Party Scribbles"
                />
                <h4 className="mt-4 mb-2">
                  No one&apos;s here yet...
                </h4>
                <h5 className="text-gray-700 max-w-3xl">
                  Add team members to get started!
                </h5>
              </div>
            ) : team?.users?.length === 0 && !isCurrentUserAnAdmin ? (
              <div className="flex flex-col items-center bg-gray-100/70 dark:bg-gray-700/30 border-2 border-dashed border-gray-50 dark:border-gray-700/50 rounded-lg py-10 px-2 w-full my-4 dark:hover:bg-gray-700/50 transition-all hover:bg-indigo-100 hover:border-indigo-200">
                <img
                  className="flex self-center"
                  width="100px"
                  src={PartyScribbles}
                  alt="Party Scribbles"
                />
                <h4 className="mt-4 mb-2">
                  No one&apos;s here yet...
                </h4>
                <h5 className="text-gray-700 max-w-3xl">
                  Contact a team admin to add team members!
                </h5>
              </div>
            ) : (
              <EditExistingTeamMembers
                team={team}
                isCurrentUserAnAdmin={isCurrentUserAnAdmin}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

function TeamHeaderInfo({ team }) {
  return (
    <div className="flex flex-col md:flex-row md:items-center bg-indigo-50 dark:bg-gray-700 shadow-sm rounded-xl p-4 my-4 md:my-10 space-x-2 md:space-x-4">
      <div className="h-10 w-10 flex items-center justify-center rounded-full bg-indigo-500 text-white ml-1 md:ml-4">
        <FontAwesomeIcon icon={faUsers} />
      </div>
      <div className="flex flex-col">
        <div className="w-44 lg:w-96">
          <h3>{team?.name}</h3>
        </div>
        <h5 className="text-xs text-gray-500">
          Team ID: {team?.uuid}
        </h5>
      </div>
    </div>
  );
}
