import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useSpring, animated, config } from "react-spring";

import OttoPeek from "../../../assets/imgs/otto-peek.svg";
import ScribblesPeek from "../../../assets/imgs/scribbles-peek.svg";
import { alert } from "../Alert";
import { BasicButton } from "../buttons/BasicButton";

export function ConfirmModal(props) {
  const {
    shouldShow,
    hideModal,
    title,
    children,
    cancelText,
    confirmText,
    cancelAction,
    confirmAction,
    disabled,
    dataCy,
    width = "max-w-xl",
    hideCancel,
    hideConfirm,
    disableCancel,
    disableConfirm,
  } = props;

  function getModalClassName(shouldShow) {
    if (shouldShow) {
      return "fixed inset-0 z-50";
    } else {
      return "hidden";
    }
  }

  useEffect(() => {
    if (shouldShow) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Clean up the effect when the component unmounts or shouldShow changes
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [shouldShow]);

  const modalAnim = useSpring({
    config: config.stiff,
    opacity: shouldShow ? 1 : 0,
    transform: shouldShow ? "scale(1)" : "scale(0.8)",
  });

  const modalClassName = getModalClassName(shouldShow);

  return (
    <div className={modalClassName}>
      <div
        className="fixed inset-0 bg-black bg-opacity-80 dark:bg-opacity-90 pointer-events-auto"
        onClick={hideModal}
      ></div>

      <div className="flex items-center justify-center min-h-screen text-center text-sm md:text-base pointer-events-none">
        <span className="hidden sm:inline-block sm:h-screen">
          &#8203;
        </span>

        <animated.div
          className={`relative inline-block rounded-xl text-left shadow-xl my-4 mx-4 align-middle ${width} w-full bg-white dark:bg-gray-800 pointer-events-auto`}
          role="dialog"
          style={modalAnim}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            onClick={() => alert("dark", "BOOP!")}
            className="absolute z-40 w-4 h-4 cursor-pointer"
            style={{ top: "-22px", left: "84px" }}
          ></div>
          <img
            style={{
              position: "absolute",
              zIndex: 30,
              top: "-44px",
              left: "40px",
            }}
            width="100px"
            src={OttoPeek}
          />
          <div
            className="float-right pr-4 pt-3 text-gray-500 hover:text-gray-600 cursor-pointer"
            onClick={hideModal}
          >
            ✖
          </div>
          <div className="px-5 pt-10 rounded-t-xl">
            <h2 className="text-left">{title}</h2>
            <div className="sm:flex sm:items-start">
              <div className="py-5 px-1 w-full max-h-80 md:max-h-[450px] overflow-y-auto">
                {children}
              </div>
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 flex justify-between w-full rounded-b-xl">
            <BasicButton
              onClick={cancelAction}
              type="button"
              importance="secondary"
              disabled={disableCancel}
              styles={hideCancel && "invisible"}
            >
              {cancelText || "Cancel"}
            </BasicButton>
            <BasicButton
              onClick={confirmAction}
              type="button"
              disabled={disabled || disableConfirm}
              importance="primary"
              dataCy={dataCy ? dataCy : "confirmButton"}
              styles={hideConfirm && "invisible"}
            >
              {confirmText || "Confirm"}{" "}
              {disabled ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  color="white"
                  className="animate-spin"
                />
              ) : null}
            </BasicButton>
          </div>
        </animated.div>
      </div>
    </div>
  );
}

export function MessageModal(props) {
  const {
    shouldShow,
    setShouldShow,
    title,
    subtitle,
    children,
    dismissable,
    width = "max-w-lg",
  } = props;
  function getModalClassName(shouldShow) {
    if (shouldShow) {
      return "fixed inset-0 z-50";
    } else {
      return "hidden";
    }
  }

  const modalAnim = useSpring({
    config: config.stiff,
    opacity: shouldShow ? 1 : 0,
    transform: shouldShow ? "scale(1)" : "scale(0.8)",
  });

  const modalClassName = getModalClassName(shouldShow);

  return (
    <div className={modalClassName}>
      <div className="flex items-center justify-center min-h-screen text-center text-sm md:text-base">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black bg-opacity-80 dark:bg-opacity-90"></div>
        </div>

        <span className="hidden sm:inline-block sm:h-screen">
          &#8203;
        </span>

        <animated.div
          className={`relative inline-block rounded-xl text-left shadow-xl my-4 mx-4 align-middle ${width} w-full`}
          role="dialog"
          style={modalAnim}
        >
          <img
            style={{
              position: "absolute",
              zIndex: 30,
              top: "-53px",
              right: "40px",
            }}
            width="100px"
            src={ScribblesPeek}
          />
          {dismissable ? (
            <div
              className="float-right pr-4 pt-3 text-gray-500 hover:text-gray-600 cursor-pointer"
              onClick={() => setShouldShow(false)}
            >
              ✖
            </div>
          ) : null}

          <div className="bg-white dark:bg-gray-800 px-5 pt-10 rounded-xl">
            <h2 className="text-left">{title}</h2>
            <h4 className="text-left font-normal text-xs md:text-sm my-2 text-gray-500 dark:text-gray-400">
              {subtitle}
            </h4>
            <div className="sm:flex sm:items-start">
              <div className="py-5 px-1 w-full max-h-80 md:max-h-[450px] overflow-y-auto flex-1">
                {children}
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
}
