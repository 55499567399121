import "./index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faCheck,
  faEdit,
  faMicrophone,
  faPaw,
  faStop,
  faTimes,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import ReactDOM from "react-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Providers from "./components/index/providers";
import App from "./components/layout/App";
import { ENVIRONMENT } from "./constants.js";

/** BEGIN SENTRY CONFIG */
if (ENVIRONMENT !== "dev") {
  Sentry.init({
    dsn: "https://2c03e9d93b444b8281f7d2ddce8c8eab@o362489.ingest.sentry.io/4215838",
    environment: ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration()],

    tracesSampleRate: 1.0,
  });
  //Set the web app version
  Sentry.setTag("web_app_version", process.env.REACT_APP_VERSION);
}

/** END SENTRY CONFIG */
let MIXPANEL_ID = "2843f411f4738d1a581a578f593c4311";
if (ENVIRONMENT == "prod") {
  MIXPANEL_ID = "f38437e64ec9df766e801e31f06fe129";
} else if (ENVIRONMENT === "qa") {
  MIXPANEL_ID = "4a601c05607c5442d1456c4812f4c577";
}

let LD_CLIENT_KEY = "";

if (ENVIRONMENT == "prod") {
  LD_CLIENT_KEY = "64e68a8c8fe28013699d2676";
} else {
  LD_CLIENT_KEY = "64e68a8c8fe28013699d2675";
}

mixpanel.init(MIXPANEL_ID, {
  debug: true,
  cookie_domain: ".scribenote.com", // Enables cross-subdomain cookies
  cross_subdomain_cookie: true,
});

if (!["qa", "prod"].includes(ENVIRONMENT)) {
  mixpanel.disable();
}

mixpanel.register({
  "Web App Version": process.env.REACT_APP_VERSION,
});

library.add(
  faCopy,
  faBars,
  faMicrophone,
  faStop,
  faPaw,
  faEdit,
  faCheck,
  faTimes,
  faWeight,
);

toast.configure();

const contextClass = {
  success: "bg-green-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-yellow-500",
  default: "bg-indigo-600",
  dark: "bg-gray-600 text-gray-100",
};

(async () => {
  const userUuid = localStorage.getItem("root_logged_in_user_uuid");
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_KEY,
    context: {
      key: userUuid ? `user-uuid-${userUuid}` : "anonymous-user",
    },
  });
  ReactDOM.render(
    <LDProvider>
      <Providers>
        <App />
        <ToastContainer
          className={"pb-14"}
          toastClassName={({ type }) =>
            contextClass[type || "default"] +
            " my-2 relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
          }
          bodyClassName={() =>
            "text-sm font-white font-med block p-3"
          }
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Providers>
    </LDProvider>,
    document.getElementById("root"),
  );
})();
