import { gql } from "@apollo/client";

const CREATE_TEAM = gql`
  mutation createTeam(
    $teamName: String!
    $location: String
    $createdAt: DateTime
    $orgUuid: String
  ) {
    createTeam(
      teamName: $teamName
      location: $location
      createdAt: $createdAt
      orgUuid: $orgUuid
    ) {
      ok
      teamUuid
    }
  }
`;

export default CREATE_TEAM;
