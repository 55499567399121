import {
  faCheckCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import mixpanel from "mixpanel-browser";
import React, { useMemo, useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSpring, animated, config } from "react-spring";

import { provisionBilling } from "../../api/Billing.js";
import {
  renderPromoCodeDetails,
  renderPromoCodeMessage,
  validateCoupon,
} from "../../api/utils.js";
import {
  ErrorMessage,
  InputWithRef,
} from "../../components/common/inputs/Input.js";
import { useAccount, useDarkMode, useAuth } from "../../hooks";
import { alert } from "../common/Alert.js";
import { GMapsInput } from "../common/inputs/GMapsInput.js";
import { RequiredAsterisk } from "../common/RequiredAsterisk";

const useOptions = () => {
  const darkModeEnabled = useDarkMode();

  let textColor = "black";
  if (darkModeEnabled[0]) {
    textColor = "#E4E4E7";
  }
  const options = useMemo(() => ({
    style: {
      base: {
        backgroundColor: "transparent",
        color: textColor,
        letterSpacing: "0.025em",
        fontFamily: "Inter, sans-serif",
        "::placeholder": {
          color: "#A1A1AA",
        },
      },
      invalid: {
        color: "#F87171",
      },
    },
  }));

  return options;
};

export default function PaymentForm({
  priceId,
  planType,
  promoCode,
  setPromoCode,
  isPromoCodeValid,
  setIsPromoCodeValid,
  promoCodeErrorMessage,
  setPromoCodeErrorMessage,
  promoCodeValue,
}) {
  // Address state
  const [structuredBillingAddress, setStructuredBillingAddress] =
    useState({
      city: null,
      country: null,
      line1: null,
      line2: null,
      postal_code: null,
      state: null,
    });

  // Stripe element focus state
  const [isCardNumberFocused, setIsCardNumberFocused] =
    useState(false);
  const [isCardExpiryFocused, setIsCardExpiryFocused] =
    useState(false);
  const [isCardCVCFocused, setIsCardCVCFocused] = useState(false);

  // Payment processing state
  const [paymentInfoErrorMessage, setPaymentInfoErrorMessage] =
    useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] =
    useState(false);
  const [isPaymentReceived, setIsPaymentReceived] = useState(false);

  const springButton = useSpring({
    config: config.wobbly,
    opacity: isPaymentReceived ? 1 : 0,
    transform: isPaymentReceived ? "scale(1)" : "scale(0.8)",
  });

  const methods = useForm({
    defaultValues: {
      promoCode: promoCodeValue || "",
    },
  });

  const {
    control,
    register,
    getValues,
    trigger,
    setFocus,
    setValue,
  } = methods;

  useEffect(() => {
    if (promoCodeValue) {
      validateCoupon(
        promoCodeValue,
        setIsPromoCodeValid,
        setPromoCode,
        setPromoCodeErrorMessage,
        planType,
      );
      setValue("promoCode", promoCodeValue);
    }
  }, [promoCodeValue, setValue]);

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const navigate = useNavigate();
  const account = useAccount();
  const { setAccountType } = useAuth();

  async function submitSubscriptionPayment() {
    const data = getValues();
    const result = await trigger();

    if (!result) {
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Payment info should always have a country on it
    if (
      paymentInfoErrorMessage ||
      !structuredBillingAddress.country ||
      !data?.cardholderName
    ) {
      alert(
        "error",
        "Please ensure all required form fields are filled out.",
      );

      // If there is an error, prevent submission until it's cleared
      return;
    }
    setIsPaymentProcessing(true);

    const { cardholderName, cardholderAddress2 } = data;
    const address = {
      city: structuredBillingAddress.city,
      country: structuredBillingAddress.country,
      line1: structuredBillingAddress.line1,
      line2: cardholderAddress2,
      postal_code: structuredBillingAddress.postal_code,
      state: structuredBillingAddress.state,
    };

    let error;

    const subscriptionJson = await provisionBilling({
      cardholderName,
      address,
      priceId,
      couponId: promoCode?.coupon?.id,
    });
    const clientSecret = subscriptionJson["clientSecret"];

    if (clientSecret?.intent) {
      const { error: confirmError } = await stripe.confirmCardSetup(
        clientSecret.intent,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardholderName,
              address: address,
            },
          },
        },
      );
      error = confirmError;
    } else {
      // Create payment method and confirm payment intent.
      const { error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardholderName,
              address: address,
            },
          },
        },
      );
      error = confirmError;
    }

    setIsPaymentProcessing(false);

    if (error) {
      // show error and collect new card details.
      alert("error", error.message);
      return;
    } else {
      // we need to set both of these because they are tracked independently
      // WEB-225 tracks the need to clean this up
      account.updateAccountContext({
        accountType: planType,
        isSubscribed: true,
      });
      setAccountType(planType);

      setIsPaymentReceived(true);
      mixpanel.track("Successful Payment", {
        planType: planType,
        promoCode: promoCode?.coupon?.id,
        priceId: priceId,
      });

      window.fbq("track", "Purchase");

      window.gtag("event", "conversion", {
        send_to: "AW-16776087259/t-FGCPeXo-oZENuNu78-",
        value: 1.0,
        currency: "USD",
        transaction_id: "",
      });

      alert(
        "success",
        "Payment Successful! 🎉 Redirecting you to the Scribenote dashboard...",
      );
      setTimeout(function () {
        navigate("/dashboard", {
          state: { shouldPreventBackNavigation: true },
        });
      }, 3500);
    }
  }

  const shouldShowSubmitPaymentButton =
    !stripe || isPaymentProcessing;

  return (
    <FormProvider {...methods}>
      <div className="w-72 divide-y dark:divide-gray-600">
        <div className="mb-4 relative">
          <label className="dark:text-gray-200 text-sm my-1">
            Promo Code
            <Controller
              control={control}
              name="promoCode"
              render={({ field: { onChange, value } }) => (
                <InputWithRef
                  placeholder=""
                  onChange={onChange}
                  onKeyDown={(event) => {
                    if (event?.code == "Enter") {
                      setFocus("address");
                    }
                  }}
                  onBlur={async () =>
                    await validateCoupon(
                      value,
                      setIsPromoCodeValid,
                      setPromoCode,
                      setPromoCodeErrorMessage,
                      planType,
                    )
                  }
                  value={value}
                />
              )}
            />
          </label>
          {isPromoCodeValid !== null && !promoCode?.referrer ? (
            <div className="absolute z-20 -right-64 top-6 w-[240px] rounded-lg shadow-sm p-3 bg-gray-50 dark:bg-gray-700/50">
              {renderPromoCodeMessage(
                promoCode,
                isPromoCodeValid,
                promoCodeErrorMessage,
              )}
              {renderPromoCodeDetails(promoCode, isPromoCodeValid)}
            </div>
          ) : promoCode?.referrer ? (
            <div className="absolute z-20 -right-64 top-6 w-[240px] rounded-lg shadow-sm p-3 bg-gray-50 dark:bg-gray-700/50">
              {renderPromoCodeMessage(
                null,
                false,
                "Referral codes cannot be applied here, please contact support to redeem your referral bonus.",
              )}
            </div>
          ) : null}
        </div>
        <div className="flex flex-col space-y-1 mt-4">
          <label className="dark:text-gray-200 text-sm mt-2 mb-1">
            Address Line 1 <RequiredAsterisk />{" "}
            <Controller
              control={control}
              name="address"
              render={({ field: { ref, onChange, value } }) => {
                return (
                  <GMapsInput
                    updateBillingAddress={setStructuredBillingAddress}
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </label>
          <label className="dark:text-gray-200 text-sm my-1">
            Address Line 2 (optional){" "}
            <InputWithRef
              placeholder="Unit 4"
              name="cardholderAddress2"
              {...register("cardholderAddress2")}
            />
          </label>
        </div>

        <div className="flex flex-col space-y-1 mt-4">
          <label className="dark:text-gray-200 text-sm mt-2 mb-1">
            Name on card <RequiredAsterisk />{" "}
            <InputWithRef
              name="cardholderName"
              placeholder="Bill Billington"
              {...register("cardholderName")}
            />
          </label>

          <label className="dark:text-gray-200 text-sm my-1">
            Card number <RequiredAsterisk />{" "}
            <div
              className={
                isCardNumberFocused
                  ? "p-2 dark:bg-gray-700 bg-gray-100 rounded-lg ring-2 ring-indigo-300"
                  : "p-2 dark:bg-gray-700 bg-gray-100 rounded-lg"
              }
            >
              <Controller
                control={control}
                name="cardNumber"
                render={({ field: { onBlur, onChange } }) => (
                  <CardNumberElement
                    options={options}
                    onChange={(event) => {
                      onChange(event);

                      if (event?.error) {
                        setPaymentInfoErrorMessage(
                          event?.error.message,
                        );
                      } else {
                        setPaymentInfoErrorMessage(null);
                      }
                    }}
                    onBlur={() => {
                      onBlur();
                      setIsCardNumberFocused(false);
                    }}
                    onFocus={() => {
                      setIsCardNumberFocused(true);
                    }}
                  />
                )}
              />
            </div>
          </label>
          <div className="flex flex-row space-x-4">
            <label className="dark:text-gray-200 text-sm my-1">
              Expiration date <RequiredAsterisk />{" "}
              <div
                className={
                  isCardExpiryFocused
                    ? "p-2 dark:bg-gray-700 bg-gray-100 rounded-lg ring-2 ring-indigo-300 w-44"
                    : "p-2 dark:bg-gray-700 bg-gray-100 rounded-lg w-44"
                }
              >
                <Controller
                  control={control}
                  name="cardExpiry"
                  render={({ field: { onBlur, onChange } }) => (
                    <CardExpiryElement
                      options={options}
                      onChange={(event) => {
                        onChange(event);
                        if (event?.error) {
                          setPaymentInfoErrorMessage(
                            event?.error.message,
                          );
                        } else {
                          setPaymentInfoErrorMessage(null);
                        }
                      }}
                      onBlur={() => {
                        onBlur();
                        setIsCardExpiryFocused(false);
                      }}
                      onFocus={() => {
                        setIsCardExpiryFocused(true);
                      }}
                    />
                  )}
                />
              </div>
            </label>
            <label className="dark:text-gray-200 text-sm my-1">
              CVC <RequiredAsterisk />{" "}
              <div
                className={
                  isCardCVCFocused
                    ? "p-2 dark:bg-gray-700 bg-gray-100 rounded-lg ring-2 ring-indigo-300 w-24 mb-4"
                    : "p-2 dark:bg-gray-700 bg-gray-100 rounded-lg w-24 mb-4"
                }
              >
                <Controller
                  control={control}
                  name="cardCVC"
                  render={({ field: { onBlur, onChange } }) => (
                    <CardCvcElement
                      options={options}
                      onChange={(event) => {
                        onChange(event);

                        if (event?.error) {
                          setPaymentInfoErrorMessage(
                            event?.error.message,
                          );
                        } else {
                          setPaymentInfoErrorMessage(null);
                        }
                      }}
                      onBlur={() => {
                        onBlur();
                        setIsCardCVCFocused(false);
                      }}
                      onFocus={() => {
                        setIsCardCVCFocused(true);
                      }}
                    />
                  )}
                />
              </div>
            </label>
          </div>
          <div className="flex flex-row items-center justify-center text-center">
            {paymentInfoErrorMessage ? (
              <ErrorMessage message={paymentInfoErrorMessage} />
            ) : null}
          </div>
          <div className="flex justify-center pt-2">
            {isPaymentReceived ? (
              <animated.button
                style={springButton}
                type="button"
                disabled={shouldShowSubmitPaymentButton}
                className={
                  "justify-self-end rounded-full w-44 px-4 py-2 text-lg font-semibold text-white bg-green-600 max-w-xs text-center cursor-default focus:outline-none"
                }
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="sm"
                  color="white"
                />
              </animated.button>
            ) : (
              <button
                onClick={() => {
                  submitSubscriptionPayment();
                }}
                disabled={shouldShowSubmitPaymentButton}
                className={`justify-self-end border border-indigo-500 rounded-full w-44 p-2 text-lg font-semibold text-white bg-indigo-500 hover:bg-indigo-600 max-w-xs text-center transition-all focus:outline-none ${
                  shouldShowSubmitPaymentButton &&
                  "opacity-50 cursor-wait"
                }`}
              >
                {isPaymentProcessing ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    color="white"
                    className="animate-spin"
                  />
                ) : (
                  "Submit Payment"
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
