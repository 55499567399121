import { gql } from "@apollo/client";

export const GET_TEAMS_USERS = gql`
  {
    user {
      uuid
      firstName
      lastName
      teams {
        numUsers
        uuid
        name
        createdAt
        location
        users {
          uuid
          firstName
          lastName
        }
      }
    }
  }
`;
