const getNumberOfNewPaidAccounts = (newUsers, existingUsers) => {
  const newPaidAccountsFromExistingUsers = existingUsers.filter(
    (user) => user.account?.accountType !== "unlimited",
  );

  return {
    newPaidAccountsFromExistingUsers:
      newPaidAccountsFromExistingUsers.length,
    netNewPaidAccounts: newUsers.length,
    totalNewPaidAccounts:
      newPaidAccountsFromExistingUsers.length + newUsers.length,
  };
};

export const getBillingIncrementText = (newUsers, existingUsers) => {
  const {
    newPaidAccountsFromExistingUsers,
    netNewPaidAccounts,
    totalNewPaidAccounts,
  } = getNumberOfNewPaidAccounts(newUsers, existingUsers);

  if (totalNewPaidAccounts === 0) {
    return "There are no new non-premium accounts. No additional charges will be incurred.";
  }

  return `Your billing will be incremented by ${totalNewPaidAccounts} subscription${
    totalNewPaidAccounts !== 1 ? "s" : ""
  }. This includes upgrading ${newPaidAccountsFromExistingUsers} existing free-plan account${
    newPaidAccountsFromExistingUsers !== 1 ? "s" : ""
  } and adding ${netNewPaidAccounts} new account${
    netNewPaidAccounts !== 1 ? "s" : ""
  }. This month's charge will be prorated based on the number of days remaining in the current billing cycle.`;
};

export const areThereAnyFailedUsers = (users) => {
  const existingUsers = users.existing;
  const newUsers = users.newUsers;

  const failedNewUsers = newUsers.filter((user) => !user.success);
  const failedExistingUsers = existingUsers.filter(
    (user) => !user.success,
  );

  if (
    failedNewUsers.length === 0 &&
    failedExistingUsers.length === 0
  ) {
    return false;
  }
  return true;
};
