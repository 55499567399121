import React from "react";

const UserList = ({ title, users, message }) => (
  <div className="mb-4">
    <h3 className="font-bold text-lg mb-2">{title}</h3>
    <p className="mb-2">{message}</p>
    <ul className="list-disc list-inside bg-gray-100/70 dark:bg-gray-700/30 border border-gray-50 dark:border-gray-700/50 rounded-lg py-2 px-4">
      {users.map((user, index) => (
        <li key={index} className="font-semibold dark:text-gray-300">
          {user.email || user}
        </li>
      ))}
    </ul>
  </div>
);

const NewUsersReceipt = ({ receiptData }) => {
  const { existing, newUsers } = receiptData;

  return (
    <div className="p-4">
      {newUsers?.length > 0 && (
        <UserList
          title="New Users Added"
          users={newUsers}
          message="The following new users have had accounts created and were added to the team:"
        />
      )}

      {existing?.length > 0 && (
        <UserList
          title="Existing Users Added"
          users={existing}
          message="The following existing users were added to the team:"
        />
      )}
    </div>
  );
};

export default NewUsersReceipt;
