import { gql } from "@apollo/client";
import { NOTE_FIELDS } from "@graphql/fragments/note";

const GET_INBOX_NOTES = gql`
  ${NOTE_FIELDS}
  query getInboxNotes(
    $filter: String!
    $limit: Int
    $offset: Int
    $teamUuid: String
    $userUuids: [String]
    $startDate: DateTime
    $endDate: DateTime
  ) {
    inboxNotes(
      filter: $filter
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      teamUuid: $teamUuid
      userUuids: $userUuids
    ) {
      totalNotes
      notes {
        patient {
          name
          lastNameOfOwner
          sex
          species
          uuid
        }
        user {
          firstName
          lastName
          uuid
        }
        ...NoteFields
      }
    }
  }
`;

export default GET_INBOX_NOTES;
