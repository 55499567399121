import { gql } from "@apollo/client";

const CURRENT_USER_QUERY = gql`
  {
    user {
      email
      firstName
      lastName
      uuid
      isScribe
      isConfirmed
      createdAt
      isOnboardingComplete
      isAppointmentsBetaUser
      referral {
        referralCode
      }
      account {
        isSubscribed
        accountType
      }
      teams {
        numUsers
        uuid
        name
        createdAt
        location
        users {
          uuid
          firstName
          lastName
        }
      }
      clientSummaryPreferences {
        frontmatter
        backmatter
        visitSummary
        abnormalPhysicalFindings
        vitals
        allFindings
        pointFormSummary
        oneLineSummary
        ownerInstructions
        medications
        vaccinations
        diagnostics
        diet
        followUpAppointments
        homeCare
      }
    }
  }
`;

export default CURRENT_USER_QUERY;
