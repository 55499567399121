import React from "react";

export default function HeadingWrapper({
  heading = null,
  children = null,
}) {
  return (
    <div className="flex flex-row items-center justify-center my-2 space-x-3">
      {heading}
      <div className="flex-1 h-0.5 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700"></div>
      {children}
    </div>
  );
}
