import { gql } from "@apollo/client";
const GET_TEAM = gql`
  query getTeam($uuid: String) {
    team(uuid: $uuid) {
      uuid
      name
      createdAt
      organization {
        uuid
        name
        emailDomain
      }
      users {
        uuid
        firstName
        lastName
        email
        numAppointmentsUsed
        numAppointmentsAllowed
        account {
          isSubscribed
        }
      }
    }
  }
`;

export default GET_TEAM;
