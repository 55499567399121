import React from "react";

import { useNoteMultiselectContext } from "../../../hooks";

export default function Tabs({
  tabCounts,
  tabArray,
  children,
  activeTab,
  id,
  setActiveTab,
  handleTabClick,
}) {
  const { resetSelectedNotesList } = useNoteMultiselectContext();

  const defaultHandleTabClick = (tabId) => {
    setActiveTab(tabId);
    resetSelectedNotesList();
  };

  const handleTabClickWithFallback =
    handleTabClick || defaultHandleTabClick;

  function Tab({
    tabTitle,
    tabColor = "bg-indigo-200 dark:bg-gray-700",
    onClick,
    index,
    notificationCount,
  }) {
    return (
      <div
        onClick={onClick}
        className={
          activeTab === index
            ? `${tabColor} py-2 pt-3 px-3 flex-1 flex items-center justify-center rounded-t-lg cursor-pointer`
            : `bg-gray-200 dark:bg-gray-700 py-2 px-3 flex-1 flex items-center justify-center rounded-t-lg cursor-pointer opacity-80 shadow-sm`
        }
      >
        <h4 className="text-gray-700 dark:text-white text-xs md:text-sm line-clamp-1">
          {tabTitle}
        </h4>
        {notificationCount ? (
          <div className="h-5 flex items-center justify-center rounded-full bg-red-400 mx-2 aspect-square">
            <h5 className="text-xs font-medium text-white dark:text-white px-1">
              {notificationCount}
            </h5>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <div id={id} className="flex flex-row items-end mx-2 flex-wrap">
        {tabArray?.map((tab) => {
          return (
            <Tab
              tabTitle={tab?.title}
              tabColor={tab?.color}
              notificationCount={
                tabCounts ? tabCounts[tab?.countKey] : null
              }
              key={tab?.id}
              index={tab?.id}
              activeTab={activeTab}
              onClick={() => handleTabClickWithFallback(tab?.id)}
            />
          );
        })}
      </div>

      <div>{children}</div>
    </div>
  );
}
