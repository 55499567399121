import { gql } from "@apollo/client";

const GET_USER_TEAMS = gql`
  query GetUserTeams($uuid: String!) {
    team(uuid: $uuid) {
      uuid
      name
      organization {
        uuid
      }
      userTeams {
        roleId
        isAdmin
        user {
          uuid
          email
          firstName
          lastName
          numAppointmentsUsed
        }
      }
    }
  }
`;

export default GET_USER_TEAMS;
