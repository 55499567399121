import { useQuery, useMutation } from "@apollo/client";
import {
  copyNotesToPatientRedirect,
  moveNotesToPatientRedirect,
} from "@components/common/multiselect/utils";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import {
  faInfoCircle,
  faTrash,
  faTruckMoving,
  faTimes,
  faPeopleArrows,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TRANSFER_NOTES } from "@graphql/mutations/TransferNotes";
import getPatientsQuery from "@graphql/queries/GetPatients.js";
import {
  useNoteMultiselect,
  useNoteMultiselectContext,
} from "@hooks";
import { useAuth } from "@hooks/use-auth";
import { useTeam } from "@hooks/use-team";
import { Tooltip } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config, animated, useTransition } from "react-spring";

import { MultiSelectActionButton } from "./MultiSelectActionButton";
import { TransferMultiModal } from "./TransferMultiModal";
import { alert } from "../Alert";

export function MultiSelectActionContainer({ notes, refetch }) {
  const navigate = useNavigate();
  const { currentTeam } = useTeam();
  const [transferNotes] = useMutation(TRANSFER_NOTES);

  const isPatientPage =
    window.location.pathname.includes("/notebook/");

  const {
    numSelectedNotes,
    selectedNoteUuids,
    setShouldShowDeleteNoteModal,
    shouldShowDeleteNoteModal,
    resetSelectedNotesList,
    numPatientNotes,
  } = useNoteMultiselectContext();

  const { shouldAllowCopyAction } = useNoteMultiselect({
    notes,
    refetch,
  });

  const [shouldShow, setShouldShow] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const { transferNotes: isTransferNotesEnabled } = useFlags();
  const { userUuid } = useAuth();
  const [newUser, setNewUser] = useState(userUuid);
  const [newTeam, setNewTeam] = useState(currentTeam);

  const { data, loading } = useQuery(getPatientsQuery, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setShouldShow(numSelectedNotes > 0);
  }, [numSelectedNotes]);

  useEffect(() => {
    resetSelectedNotesList();
  }, [currentTeam]);

  const transitions = useTransition(shouldShow, {
    from: { opacity: 0, y: 120 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 120 },
    config: config.default,
  });

  if (shouldShowDeleteNoteModal || loading) {
    return null;
  }

  const confirmAction = async () => {
    if (numPatientNotes > 0) {
      alert(
        "error",
        "Unsuccessful Note Transfer: You can't transfer notes associated with one of your patients.",
      );
    } else {
      try {
        const { data } = await transferNotes({
          variables: {
            noteUuids: Object.keys(selectedNoteUuids),
            newTeamUuid: newTeam?.uuid,
            newOwnerUuid: newUser,
          },
          update: (cache) => {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "inboxTabCount",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "inboxNotes",
            });
          },
        });

        if (data.transferNoteOwnership.failedNotes?.length) {
          alert(
            "error",
            "Some notes failed to transfer. Are you sure you are the owner of all the notes you are transfering?",
          );
          mixpanel.track("Failed to transfer notes", {
            transferredNoteUuids: selectedNoteUuids,
            failedNotes: data.transferNoteOwnership.failedNotes,
            newNoteOwner: newUser,
            newNoteTeam: newTeam?.uuid,
            previousNoteOwner: localStorage.getItem("user_uuid"),
          });
        } else {
          alert("success", "Notes transferred successfully!");
          mixpanel.track("Transferred Note", {
            transferredNoteUuids: selectedNoteUuids,
            newNoteOwner: newUser,
            newNoteTeam: newTeam?.uuid,
            previousNoteOwner: localStorage.getItem("user_uuid"),
          });
        }
        refetch();
      } catch (error) {
        alert(
          "error",
          "Unsuccessful Note Transfer. Please try again later. If the issue persists, please contact support.",
        );
        mixpanel.track("Transfer Note Error", {
          transferredNoteUuids: selectedNoteUuids,
          newNoteOwner: newUser,
          previousNoteOwner: localStorage.getItem("user_uuid"),
          transferNoteError: error,
        });
      }
      setIsModalShowing(false);
      resetSelectedNotesList();
    }
  };

  return transitions((styles, item) =>
    item ? (
      <animated.div
        style={styles}
        className="fixed bottom-0 left-0 z-30 w-full flex flex-col items-center justify-center pointer-events-none"
      >
        <TransferMultiModal
          notes={notes}
          shouldShowTransferModal={isModalShowing}
          setShouldShowTransferModal={setIsModalShowing}
          refetch={refetch}
          confirmAction={confirmAction}
          loading={loading}
          newUser={newUser}
          setNewUser={setNewUser}
          newTeam={newTeam}
          setNewTeam={setNewTeam}
        />
        <div
          style={{
            boxShadow: "0px -5px 50px -10px rgba(0,0,0,0.40)",
          }}
          className="relative flex flex-col items-center bg-white dark:bg-gray-800 pt-4 pb-2 px-10 rounded-t-2xl pointer-events-auto"
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute top-4 right-4 text-red-500 cursor-pointer"
            onClick={resetSelectedNotesList}
          />
          <div className="flex flex-row items-center justify-between w-full">
            <h4 className="flex-1 text-center mb-3 text-gray-500 dark:text-gray-400">
              ⚡️ {numSelectedNotes} note
              {numSelectedNotes > 1 ? "s" : ""} selected
            </h4>
          </div>
          <div className="flex flex-wrap md:flex-row space-x-2 space-y-2 md:space-y-0 items-center justify-center">
            {numSelectedNotes > 1 && (
              <Tooltip
                arrow
                title="Please ensure you have popups enabled or this feature will not work as expected."
              >
                {/* Have to wrap in a div because of mui tooltip limitations on custom elements */}
                <div>
                  <MultiSelectActionButton
                    onClickActionDescription={"Open all in new tabs"}
                    onClick={() => {
                      Object.keys(selectedNoteUuids).map((noteUuid) =>
                        window.open(
                          `/edit_note/${noteUuid}`,
                          "_blank",
                        ),
                      );
                    }}
                    icon={faUpRightFromSquare}
                  />
                </div>
              </Tooltip>
            )}
            {data?.patients?.length > 0 && (
              <>
                <MultiSelectActionButton
                  onClickActionDescription={"Move to patient"}
                  onClick={() =>
                    moveNotesToPatientRedirect({
                      selectedNoteUuids,
                      navigate,
                    })
                  }
                  icon={faTruckMoving}
                  disabled={!shouldAllowCopyAction}
                />
              </>
            )}

            <MultiSelectActionButton
              onClickActionDescription={"Duplicate to patient"}
              onClick={() =>
                copyNotesToPatientRedirect({
                  selectedNoteUuids,
                  navigate,
                })
              }
              icon={faClone}
              disabled={!shouldAllowCopyAction}
            />

            {isTransferNotesEnabled && currentTeam?.uuid && (
              <MultiSelectActionButton
                onClickActionDescription={"Transfer ownership"}
                onClick={() => setIsModalShowing(true)}
                icon={faPeopleArrows}
                disabled={!shouldAllowCopyAction || isPatientPage}
              />
            )}
            <MultiSelectActionButton
              onClickActionDescription={"Delete"}
              onClick={() => setShouldShowDeleteNoteModal(true)}
              icon={faTrash}
              buttonColor="bg-red-400 hover:bg-red-500"
            />
          </div>
          {!shouldAllowCopyAction && (
            <h5 className="text-xs text-indigo-400 dark:text-gray-500 pt-2 text-center">
              <FontAwesomeIcon icon={faInfoCircle} /> You can&#39;t
              move, duplicate, or transfer notes labeled
              &#39;Processing&#39; or &#39;New Recording&#39;.
            </h5>
          )}
          {isPatientPage && (
            <h5 className="text-xs text-indigo-400 dark:text-gray-500 pt-2 text-center">
              <FontAwesomeIcon icon={faInfoCircle} /> You can&#39;t
              transfer notes that are assigned to a patient.
            </h5>
          )}
        </div>
      </animated.div>
    ) : null,
  );
}
