import { gql } from "@apollo/client";

const REMOVE_USER_FROM_TEAM = gql`
  mutation removeUserFromTeam(
    $teamUuid: String!
    $userUuid: String!
  ) {
    removeUserFromTeam(teamUuid: $teamUuid, userUuid: $userUuid) {
      ok
      removedUser {
        success
        email
      }
    }
  }
`;

export default REMOVE_USER_FROM_TEAM;
